<form class="pt-4" [formGroup]="form">
  <div class="p-fluid grid">
    <div class="field col-12 md:col-11">
      <span class="p-float-label">
        <input type="text" id="inputtext" pInputText [formControl]="paramsForm.controls.libraryPath" />
        <label for="inputtext">Source</label>
      </span>
    </div>
    <div class="field col-12 md:col-1">
      <button pButton type="button" label="Browse" (click)="onShowDialog()"></button>
    </div>

    <div class="field col-12 md:col-6">
      <p-dropdown
        [options]="format"
        optionLabel="label"
        optionValue="value"
        [formControl]="paramsForm.controls.fileFormat"
      ></p-dropdown>
    </div>

    <ng-container *ngIf="paramsForm.controls.csvFileFormatParams as csvFileFormatParamsForm">
      <ng-container *ngIf="paramsForm.controls.fileFormat.value === 'csv'">
        <div class="field col-12 md:col-3">
          <p-checkbox
            label="First row includes column names"
            [binary]="true"
            [formControl]="csvFileFormatParamsForm.controls.includeHeader"
          ></p-checkbox>
        </div>
        <div class="field col-12 md:col-3">
          <p-checkbox
            label="Convert 0 and 1 to boolean"
            [binary]="true"
            [formControl]="csvFileFormatParamsForm.controls.convert01ToBoolean"
          ></p-checkbox>
        </div>

        <h5 class="col-12">Separator</h5>
        <div class="col-12 flex gap-3 mb-5">
          <div class="flex align-items-center" *ngFor="let separator of separators">
            <p-radioButton
              [label]="separator.label"
              [value]="separator.id"
              [formControl]="csvFileFormatParamsForm.controls.separatorType"
            ></p-radioButton>
          </div>

          <div class="p-float-label">
            <input class="w-full" pInputText [formControl]="csvFileFormatParamsForm.controls.customSeparator" />
            <label>Custom</label>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>

  <div class="mb-5">
    <selfai-platform-data-source-visibility
      [control]="form.controls.visibility"
    ></selfai-platform-data-source-visibility>
  </div>

  <div class="mb-5">
    <selfai-platform-data-source-unique-name [control]="form.controls.name"></selfai-platform-data-source-unique-name>
  </div>

  <selfai-platform-data-sources-additionals
    [properties]="dataSource?.params?.properties"
    [additionalList]="additionalList"
    (changeEvent)="onPropertiesValue($event)"
  ></selfai-platform-data-sources-additionals>
</form>
