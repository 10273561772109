import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserFunctionsListItem } from '../models';
import { UserFunctionsListStore } from '../store';

@Injectable({ providedIn: 'root' })
export class UserFunctionDomainService {
  loading$: Observable<boolean> = this.userFunctionsListStore.loading$;

  constructor(private userFunctionsListStore: UserFunctionsListStore) {}

  updateFunction(data: Partial<UserFunctionsListItem>): Observable<UserFunctionsListItem> {
    return this.userFunctionsListStore.update(data);
  }

  addFunction(data: UserFunctionsListItem): Observable<UserFunctionsListItem> {
    return this.userFunctionsListStore.add(data);
  }

  getFunction(id: string): Observable<UserFunctionsListItem> {
    return this.userFunctionsListStore.getByKey(id);
  }
}
