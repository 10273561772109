import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { TreeModule } from 'primeng/tree';
import { OperationsApiService } from '../../services/operations/operations-api.service';
import { OperationsAdapter } from '../../services/operations/operations.adapter';
import { CubeDndPlaceholderComponentModule } from '../cube-dnd-placeholder/cube-dnd-placeholder.module';
import { OperationListComponent } from './operation-list.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [CommonModule, TreeModule, ButtonModule, DragDropModule, CubeDndPlaceholderComponentModule, TooltipModule, TranslateModule],
  declarations: [OperationListComponent],
  exports: [OperationListComponent],
  providers: [{ provide: OperationsAdapter, useClass: OperationsApiService }],
})
export class OperationListComponentModule {}
