import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { PipelinePermission, UrlPageParamsService } from '@selfai-platform/shared';
import {
  convertMessageToHtml,
  DataListViewComponentService,
  GroupAction,
  KE_DATASOURCES_PATH,
  KE_ROOT_ROUTE,
  MenuItemsNormalizerService,
  SelectedItemsService,
  TableColumn,
} from '@selfai-platform/shell';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { combineLatest, concatMap, EMPTY, from, map, Observable, of, switchMap, take, toArray } from 'rxjs';
import { DataSourcesIcon } from '../enums/data-sources-icon.enum';
import { DataSourceApi, DataSourceListItem } from '../model';
import { getIcon, getVisibility } from '../utilities';
import { DataSourceListDomainService } from './data-source-list-domain.service';
import { DataSourcePermissionService } from './data-source-permission.service';

@Injectable()
export class DataSourceDataListViewService extends DataListViewComponentService<DataSourceListItem> {
  constructor(
    private readonly dataSourceListDomainService: DataSourceListDomainService,
    private readonly confirmationService: ConfirmationService,
    private readonly translate: TranslateService,
    private readonly datasourcePermissionService: DataSourcePermissionService,
    private readonly router: Router,
    private readonly menuItemsNormalizerService: MenuItemsNormalizerService,
    urlPageParamsService: UrlPageParamsService,
    selectedItemsService: SelectedItemsService<DataSourceListItem>,
  ) {
    super(urlPageParamsService, selectedItemsService);
  }

  override loadData(): Observable<{ items: DataSourceListItem[]; totalItems: number }> {
    return this.dataSourceListDomainService.loadDataSourceList().pipe(
      map(({ dataSources }) => ({
        items: dataSources.map((item) => this.normalizeForView(item)),
        totalItems: dataSources.length,
      })),
    );
  }

  override getData(): Observable<DataSourceListItem[]> {
    return this.dataSourceListDomainService.getDataSourceList().pipe(
      map((dataSourceList) => dataSourceList),
      concatMap((streamedDataSourceList) => from(streamedDataSourceList).pipe(
          concatMap((streamedDatasource: DataSourceApi) =>
            combineLatest([
              of(this.normalizeForView(streamedDatasource)),
              this.getRouterLink(streamedDatasource),
            ]).pipe(
              map(([datasource, routerLink]) => ({ ...datasource, ...routerLink }))
            )
          ),
          toArray()
        )
      ),
    );
  }

  override isLoading(): Observable<boolean> {
    return this.dataSourceListDomainService.getDataSourceListLoading();
  }

  override isLoaded(): Observable<boolean> {
    return this.dataSourceListDomainService.getDataSourceListLoaded();
  }

  override hasError(): Observable<boolean> {
    return this.dataSourceListDomainService.getDataSourceListError().pipe(map((error) => !!error));
  }

  getColumns(): TableColumn<DataSourceListItem>[] {
    return [
      {
        labelTranslate: 'data-source-list.table.columns.name',
        fieldCode: 'name',
        fieldType: 'text',
        classStyle: 'no-breaks short-text',
        width: 'calc(50% - 41rem)',
        resizable: true,
        sortable: true,
      },
      {
        labelTranslate: 'data-source-list.table.columns.created',
        fieldCode: 'creationDateTime',
        fieldType: 'date',
        classStyle: 'no-breaks',
        width: '10rem',
        resizable: true,
        sortable: true,
      },

      {
        labelTranslate: 'data-source-list.table.columns.type',
        fieldCode: 'datasourceType',
        fieldType: 'text',
        classStyle: 'no-breaks',
        width: '9rem',
        resizable: true,
        sortable: true,
      },
      {
        labelTranslate: 'data-source-list.table.columns.owner-name',
        fieldCode: 'ownerName',
        fieldType: 'text',
        classStyle: 'no-breaks short-text',
        width: '9rem',
        resizable: true,
        sortable: true,
      },
      {
        labelTranslate: 'data-source-list.table.columns.visibility',
        fieldCode: 'visibility',
        fieldType: 'text',
        classStyle: 'no-breaks',
        sortable: true,
        resizable: true,
        width: '9rem',
      },
    ];
  }

  getGroupActions(): Observable<GroupAction[]> {
    if (this.datasourcePermissionService.hasCurrentPermission(PipelinePermission.DatasourcesDeleteAny)) {
      return of([{
        tooltipTranslate: 'workflow-list.toolbar.actions.delete-selected',
          icon: 'pi pi-trash',
          buttonClass: 'p-button-danger',
          action: () => this.deleteSelected(),
      }]);
    } else {
      return EMPTY;
    }
  }

  getCreateMenu(): Observable<MenuItem[]> {
    return of(this.datasourcePermissionService.canCreateSource()).pipe(
      map((canCreateSource) => {
        if (canCreateSource) {
          return [
            {
              labelTranslate: 'data-source-list.toolbar.actions.create.library',
              icon: DataSourcesIcon.LIBRARY,
              action: () => this.router.navigate(['/', KE_ROOT_ROUTE, KE_DATASOURCES_PATH, 'create', 'libraryFile']),
            },
            {
              labelTranslate: 'data-source-list.toolbar.actions.create.database',
              icon: DataSourcesIcon.DATABASE,
              action: () => this.router.navigate(['/', KE_ROOT_ROUTE, KE_DATASOURCES_PATH, 'create', 'jdbc']),
            },
            {
              labelTranslate: 'data-source-list.toolbar.actions.create.external-file',
              icon: DataSourcesIcon.EXTERNAL_FILE,
              action: () => this.router.navigate(['/', KE_ROOT_ROUTE, KE_DATASOURCES_PATH, 'create', 'externalFile']),
            },
            {
              labelTranslate: 'data-source-list.toolbar.actions.create.google-spreadsheet',
              icon: DataSourcesIcon.GOOGLE_SP,
              action: () =>
                this.router.navigate(['/', KE_ROOT_ROUTE, KE_DATASOURCES_PATH, 'create', 'googleSpreadsheet']),
            },
            {
              labelTranslate: 'data-source-list.toolbar.actions.create.hd',
              icon: DataSourcesIcon.HD,
              action: () => this.router.navigate(['/', KE_ROOT_ROUTE, KE_DATASOURCES_PATH, 'create', 'hdfs']),
            },
            {
              labelTranslate: 'data-source-list.toolbar.actions.create.kafka',
              icon: DataSourcesIcon.KAFKA,
              action: () => this.router.navigate(['/', KE_ROOT_ROUTE, KE_DATASOURCES_PATH, 'create', 'kafka']),
            },
            {
              labelTranslate: 'data-source-list.toolbar.actions.create.s3',
              icon: DataSourcesIcon.S3,
              action: () => this.router.navigate(['/', KE_ROOT_ROUTE, KE_DATASOURCES_PATH, 'create', 's3']),
            },
          ];
        }

        return [];
      }),
      switchMap((items) => this.menuItemsNormalizerService.mapToolbarActionsToMenu(items)),
    );
  }

  private deleteSelected(): void {
    // TODO: Move to the confirmation with key = dialog to data list view
    this.confirmationService.confirm({
      key: 'dialog',
      message: convertMessageToHtml(
        this.translate.instant('data-source-list.toolbar.actions.delete-selected.confirmation'),
      ),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.selectedItemsService
          .getSelectedItemIds()
          .pipe(take(1))
          .subscribe((ids) => {
            ids.forEach((datasourceId) => {
              this.dataSourceListDomainService.deleteDataSource(datasourceId);
            });
          });
      },
    });
  }

  private normalizeForView(dataSource: DataSourceApi): DataSourceListItem {
    return {
      ...dataSource,
      icon: getIcon(dataSource.params.datasourceType),
      name: dataSource.params.name,
      datasourceType: dataSource.params.datasourceType,
      visibility: getVisibility(dataSource.params.visibility),
    } as DataSourceListItem;
  }

  private getRouterLink(dataSource: DataSourceApi): Observable<DataSourceListItem> {
    return this.datasourcePermissionService.canViewSource(dataSource.ownerId).pipe(map((canView) => {
      if (canView) {
        return {
          routerLinkToItem: {
            routerLink: ['/', KE_ROOT_ROUTE, KE_DATASOURCES_PATH, 'edit', dataSource.id],
          },
        } as DataSourceListItem
      } else {
        return null;
      }
    }));
  }
}
