import { HttpBackend } from '@angular/common/http';
import { Injector, ModuleWithProviders } from '@angular/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { SHELL_CONFIG, ShellModuleConfig } from '../shell-config';
import { SelfaiAppModules } from '@selfai-platform/shared';

function httpLoaderFactory(_httpBackend: HttpBackend, injector: Injector) {
  const shellConfig = injector.get(SHELL_CONFIG);
  const loadTranslationPaths = ['/assets/i18n/prime/', '/assets/i18n/shell/'];
  if (shellConfig.modules.includes(SelfaiAppModules.KD)) {
    loadTranslationPaths.push('/assets/bi/i18n/');
  }
  if (shellConfig.modules.includes(SelfaiAppModules.KE)) {
    loadTranslationPaths.push('/assets/i18n/pipeline/');
  }

  return new MultiTranslateHttpLoader(_httpBackend, loadTranslationPaths);
}

export function provideShellTranslateModule(): ModuleWithProviders<TranslateModule> {
  return TranslateModule.forRoot({
    loader: {
      provide: TranslateLoader,
      useFactory: httpLoaderFactory,
      deps: [HttpBackend, Injector],
    },
    extend: true,
  });
}
