export enum WorkflowEditorActions {
  OpenNodeModalDialogUnselectedNode = 'Workflow.OpenNodeModalDialogUnselectedNode',
  OpenNodeModalDialogSelectedNode = 'Workflow.OpenNodeModalDialogSelectedNode',
  MoveToNode = 'Workflow.MoveToNode',
  SelectNode = 'Workflow.SelectNode',
  AddNode = 'Workflow.AddNode',
  CloneWorkflow = 'Workflow.CloneWorkflow',
  ReOpen = 'Workflow.ReOpen',
  UpdateNodeProperties = 'Workflow.UpdateNodeProperties',
  UpdateNodeParamterValues = 'Workflow.UpdateNodeParamterValues',
  SaveAndOpen = 'Workflow.SaveAndOpen',
  RunWorkflow = 'Workflow.Run',
  CubeCommentSave = 'CubeCommentSave',
  SaveVariables = 'SaveVariables',
  UnselectNode = 'Workflow.UnselectNode',
  RestoreNodes = 'Workflow.RestoreNodes',
  RemoveFromRecycleBin = 'Workflow.RemoveFromRecycleBin',
  SaveReferenceButtons = 'Workflow.SaveReferenceButtons',
}
