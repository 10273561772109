import { Injectable } from '@angular/core';
import {
  DatasourcePermissionAction,
  FunctionPermissionAction,
  PermissionService,
  PipelinePermissionEntity,
  PresetsPermissionAction,
  SchedulerPermissionAction,
  WorkflowPermissionAction,
} from '@selfai-platform/shared';
import { MenuItem } from 'primeng/api';
import { BehaviorSubject, switchMap, combineLatest } from 'rxjs';
import {
  KE_DATASOURCES_PATH,
  KE_ROOT_ROUTE,
  KE_PRESETS_PATH,
  KE_USER_FUNCTIONS_PATH,
  KE_SCHEDULES_PATH,
} from '../navigation';

@Injectable({ providedIn: 'root' })
export class PipelineMenuItemService {
  private pipelineMenuItems = new BehaviorSubject<MenuItem[]>([]);
  private menuItemsMap;

  public pipelineMenuItems$ = this.pipelineMenuItems.asObservable();

  constructor(private readonly permissionService: PermissionService) {
    this.menuItemsMap = new Map<PipelinePermissionEntity, MenuItem>([
      [
        PipelinePermissionEntity.Workflows,
        {
          label: 'shell.menu.workflows',
          icon: 'pi pi-fw pi-sitemap',
          routerLink: ['/', KE_ROOT_ROUTE],
        },
      ],
      [
        PipelinePermissionEntity.Datasources,
        {
          label: 'shell.menu.data-sources',
          icon: 'pi pi-fw pi-database',
          routerLink: ['/', KE_ROOT_ROUTE, KE_DATASOURCES_PATH],
        },
      ],
      [
        PipelinePermissionEntity.Presets,
        {
          label: 'shell.menu.presets',
          icon: 'pi pi-fw pi-microchip',
          routerLink: ['/', KE_ROOT_ROUTE, KE_PRESETS_PATH],
        },
      ],
      [
        PipelinePermissionEntity.Functions,
        {
          label: 'shell.menu.functions',
          icon: 'pi pi-fw pi-wave-pulse',
          routerLink: ['/', KE_ROOT_ROUTE, KE_USER_FUNCTIONS_PATH],
        },
      ],
      [
        PipelinePermissionEntity.Schedules,
        {
          label: 'shell.menu.schedules',
          icon: 'pi pi-fw pi-calendar-clock',
          routerLink: ['/', KE_ROOT_ROUTE, KE_SCHEDULES_PATH],
        },
      ],
    ]);

    this.permissionService.pipelinePermissionsLoadedObs$
      .pipe(
        switchMap(() =>
          combineLatest({
            workflows: this.permissionService.checkPermission({
              entity: PipelinePermissionEntity.Workflows,
              action: WorkflowPermissionAction.List,
            }),
            datasources: this.permissionService.checkPermission({
              entity: PipelinePermissionEntity.Datasources,
              action: DatasourcePermissionAction.List,
            }),
            presets: this.permissionService.checkPermission({
              entity: PipelinePermissionEntity.Presets,
              action: PresetsPermissionAction.List,
            }),
            functions: this.permissionService.checkPermission({
              entity: PipelinePermissionEntity.Functions,
              action: FunctionPermissionAction.List,
            }),
            scheduling: this.permissionService.checkPermission({
              entity: PipelinePermissionEntity.Schedules,
              action: SchedulerPermissionAction.List,
            }),
          }),
        ),
      )
      .subscribe((permissions) => {
        this.setMenuItemsState(permissions);
      });
  }

  private setMenuItemsState(permissions: Record<string, boolean>) {
    const availableItems = Object.entries(permissions)
      .filter(([, value]) => value)
      .map(([key, _value]) => this.menuItemsMap.get(key as PipelinePermissionEntity));

    this.pipelineMenuItems.next([
      {
        id: 'engine',
        label: 'shell.menu.knowledge-engine',
        state: { sort: 300 },
        items: [...availableItems],
      },
    ]);
  }
}
