import { Injectable } from '@angular/core';
import { combineLatest, map, Observable, switchMap } from 'rxjs';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ConfirmationService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { ActionsForItemService, convertMessageToHtml, DataListItemAction } from '@selfai-platform/shell';
import { UserFunctionsListItem } from '../models';
import { UserFunctionCloneDialogComponent } from '../components';
import { UserFunctionsDataService } from '../store';
import { UserFunctionsListDomainService } from './user-functions-list-domain.service';
import { UserFunctionsPermissionService } from './user-functions-permission.service';

@Injectable()
export class UserFunctionsActionsForItemService extends ActionsForItemService<UserFunctionsListItem> {
  public ref: DynamicDialogRef | undefined;
  constructor(
    private readonly userFunctionsListDomainService: UserFunctionsListDomainService,
    private readonly userFunctionsDataService: UserFunctionsDataService,
    private readonly userFunctionsPermissionService: UserFunctionsPermissionService,
    private readonly confirmationService: ConfirmationService,
    private readonly translateService: TranslateService,
    private readonly dialogService: DialogService,
  ) {
    super();
  }

  override getActionsForItem(userFunction: UserFunctionsListItem): Observable<DataListItemAction[]> {
    return this.userFunctionsPermissionService.canDeleteUserFunction(userFunction.ownerId as string).pipe(switchMap((canDelete) =>
      combineLatest({
        copyTooltipTranslate: this.translateService.get('user-functions-list.actions.copy'),
        deleteTooltipTranslate: this.translateService.get('user-functions-list.actions.delete'),
      }).pipe(
        map(({
               copyTooltipTranslate,
               deleteTooltipTranslate, }) => {
          let items = [{
            labelTranslate: copyTooltipTranslate,
            icon: 'pi pi-copy',
            action: () => this.clone(userFunction),
          }];

          if (canDelete) {
            items = [...items, {
              labelTranslate: deleteTooltipTranslate,
              icon: 'pi pi-trash',
              action: () => this.delete(userFunction),
            }]
          }

          return items as DataListItemAction[];
        }),
      )))
  }

  private clone(userFunctionsListItem: UserFunctionsListItem): void {
    this.ref = this.dialogService.open(UserFunctionCloneDialogComponent, {
      width: '40vw',
      modal: true,
      breakpoints: {
        '960px': '75vw',
        '640px': '90vw'
      },
      data: userFunctionsListItem
    });

    this.ref.onClose.subscribe((data) => {
      if (data) {
        const sub = this.userFunctionsDataService.clone(userFunctionsListItem.id, data).subscribe((newId) => {
          this.userFunctionsDataService.add({ ...userFunctionsListItem, id: newId });
          sub.unsubscribe();
        });
      }
    })
  }

  private delete(userFunction: UserFunctionsListItem): void {
    this.confirmationService.confirm({
      key: 'dialog',
      message: convertMessageToHtml(this.translateService.instant('user-functions-list.actions.delete.confirmation')),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.userFunctionsListDomainService.deleteUserFunction(userFunction.id);
      },
    });
  }
}
