import { Injectable } from '@angular/core';
import { filter, map, Observable } from 'rxjs';
import { WorkflowState, WorkflowStore } from '../../stores';

@Injectable({ providedIn: 'root' })
export class WorkflowDomainService {
  constructor(private readonly store: WorkflowStore) {}

  loadWorkflow(workflowId: string): Observable<WorkflowState> {
    return this.store.getByKey(workflowId);
  }

  getWorkflow(workflowId: string): Observable<WorkflowState> {
    return this.store.entityMap$.pipe(
      map((entities) => entities?.[workflowId]),
      filter(Boolean),
    );
  }

  getWorkflowLoading(): Observable<boolean> {
    return this.store.loading$;
  }

  getWorkflowError(): Observable<unknown> {
    return this.store.errors$;
  }

  getWorkflowSuccess(): Observable<boolean> {
    return this.store.loaded$;
  }
}
