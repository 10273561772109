import { Injectable } from '@angular/core';
import { map, Observable, combineLatest } from 'rxjs';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { ConfirmationService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { ActionsForItemService, convertMessageToHtml, DataListItemAction } from '@selfai-platform/shell';
import { SchedulesListItem } from '../models';
import { SchedulesListDataService } from '../store';
import { SchedulesListDomainService } from './schedules-list-domain.service';
import { SchedulesWorkflowsListStore } from '../store/workflows-list.store';
import { SchedulerPermissionAction } from '@selfai-platform/shared';
import { SchedulesPermissionService } from './schedules-permission.service';

@Injectable({
  providedIn: 'root',
})
export class SchedulesActionsForItemsService extends ActionsForItemService<SchedulesListItem> {
  public ref: DynamicDialogRef | undefined;
  constructor(
    private readonly schedulesListDomainService: SchedulesListDomainService,
    private readonly schedulesDataService: SchedulesListDataService,
    private readonly schedulesWorkflowsListStore: SchedulesWorkflowsListStore,
    private readonly confirmationService: ConfirmationService,
    private readonly translateService: TranslateService,
    private readonly permissionService: SchedulesPermissionService,
  ) {
    super();
  }

  override getActionsForItem(schedule: SchedulesListItem): Observable<DataListItemAction[]> {
    return combineLatest({
      deleteTooltipTranslate: this.translateService.get('schedules-list.actions.delete'),
      deletePermission: this.permissionService.checkPermission(SchedulerPermissionAction.Delete),
    }).pipe(
      map(({ deleteTooltipTranslate, deletePermission }) => {
        if (deletePermission) {
          return [
            {
              labelTranslate: deleteTooltipTranslate,
              icon: 'pi pi-trash',
              action: () => this.delete(schedule),
            },
          ] as DataListItemAction[];
        } else {
          return [];
        }
      })
    );
  }

  private delete(schedulesListItem: SchedulesListItem): void {
    this.confirmationService.confirm({
      key: 'dialog',
      message: convertMessageToHtml(this.translateService.instant('schedules-list.actions.delete.confirmation')),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.schedulesListDomainService.deleteSchedule(schedulesListItem.id);
      },
    });
  }
}
