import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { CalendarModule } from 'primeng/calendar';
import { ChipModule } from 'primeng/chip';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { OverlayModule } from 'primeng/overlay';
import { SliderModule } from 'primeng/slider';
import { TooltipModule } from 'primeng/tooltip';

import { BiSharedModule } from '@selfai-platform/bi-shared';

import { CommonModule } from '../common/common.module';

import { CustomFieldIconComponent } from './component/custom-field/custom-field-icon/custom-field-icon.component';
import { CustomFieldComponent } from './component/custom-field/custom-field.component';
import { BoundFilterPanelComponent } from './filters/bound-filter/bound-filter-panel.component';
import { BoundFilterComponent } from './filters/bound-filter/bound-filter.component';
import { ConfigureFiltersBoundComponent } from './filters/bound-filter/configure-filters-bound.component';
import { FilterSelectComponent } from './filters/component/filter-select/filter-select.component';
import { TimeRangeComponent } from './filters/component/time-range.component';
import { TimeUnitSelectComponent } from './filters/component/timeUnit-select.component';
import { ConfigureFiltersSelectComponent } from './filters/configure-filters-select.component';
import { ConfigureFiltersUpdateComponent } from './filters/configure-filters-update.component';
import { ConfigureFiltersComponent } from './filters/configure-filters.component';
import { EssentialFilterComponent } from './filters/essential-filter/essential-filter.component';
import { ConfigureFiltersInclusionComponent } from './filters/inclusion-filter/configure-filters-inclusion.component';
import { InclusionFilterPanelComponent } from './filters/inclusion-filter/inclusion-filter-panel.component';
import { ConfigureFiltersTimeComponent } from './filters/time-filter/configure-filters-time.component';
import { TimeFilterPanelComponent } from './filters/time-filter/time-filter-panel.component';
import { TimeListFilterComponent } from './filters/time-filter/time-list-filter.component';
import { TimeRangeFilterComponent } from './filters/time-filter/time-range-filter.component';
import { TimeRelativeFilterComponent } from './filters/time-filter/time-relative-filter.component';

@NgModule({
  imports: [
    CommonModule,
    InfiniteScrollModule,
    SliderModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    OverlayModule,
    DropdownModule,
    InputNumberModule,
    CalendarModule,
    ChipModule,
    TooltipModule,
    BiSharedModule,
  ],
  declarations: [
    FilterSelectComponent,
    TimeRangeComponent,
    InclusionFilterPanelComponent,
    BoundFilterComponent,
    BoundFilterPanelComponent,
    ConfigureFiltersComponent,
    ConfigureFiltersSelectComponent,
    ConfigureFiltersUpdateComponent,
    ConfigureFiltersInclusionComponent,
    ConfigureFiltersBoundComponent,
    EssentialFilterComponent,
    CustomFieldComponent,
    TimeUnitSelectComponent,
    TimeFilterPanelComponent,
    ConfigureFiltersTimeComponent,
    TimeRangeFilterComponent,
    TimeRelativeFilterComponent,
    TimeListFilterComponent,
    CustomFieldIconComponent,
  ],
  exports: [
    FilterSelectComponent,
    TimeRangeComponent,
    InclusionFilterPanelComponent,
    BoundFilterComponent,
    BoundFilterPanelComponent,
    ConfigureFiltersComponent,
    ConfigureFiltersSelectComponent,
    ConfigureFiltersUpdateComponent,
    ConfigureFiltersInclusionComponent,
    ConfigureFiltersBoundComponent,
    EssentialFilterComponent,
    CustomFieldComponent,
    TimeUnitSelectComponent,
    TimeFilterPanelComponent,
    ConfigureFiltersTimeComponent,
    TimeRangeFilterComponent,
    TimeRelativeFilterComponent,
    TimeListFilterComponent,
    CustomFieldIconComponent,
  ],
})
export class DashboardShareModule {}
