@if (hasParameters) {
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <selfai-platform-form-field label="{{ 'workflow.cubes.enrichment-tool.field-label.sql-query' | translate }}">
      <selfai-platform-code-editor
        class="p-inputtext"
        language="sparksql"
        [formControl]="form.controls.sqlQuery"
      ></selfai-platform-code-editor>
    </selfai-platform-form-field>

    <selfai-platform-form-field label="{{ 'workflow.cubes.enrichment-tool.field-label.data-source' | translate }}">
      <selfai-platform-data-source-selector
        [control]="form.controls.dataSourceId"
        class="h-15rem"
      ></selfai-platform-data-source-selector>
    </selfai-platform-form-field>
  </form>
} @else {
  <p-message text="{{ 'workflow.cubes.enrichment-tool.no-parameters' | translate }}" severity="error"></p-message>
}
