import { Route } from '@angular/router';
import { AppAuthGuard, PipelinePermission } from '@selfai-platform/shared';
import { WorkflowPresetsContainerComponent, WorkflowPresetsListContainerComponent } from './workflow-presets/';
import { DataSourcesContainerComponent, DataSourcesListContainerComponent } from './data-sources';
import { WorkflowEditorComponent } from './workflow-editor';
import {
  KE_DATASOURCES_PATH,
  KE_PRESETS_PATH,
  KE_ROOT_ROUTE,
  KE_WORKFLOW_PATH,
  KE_USER_FUNCTIONS_PATH,
  KE_SCHEDULES_PATH,
} from '@selfai-platform/shell';
import { WorkflowSettingsContainerComponent } from './workflow-settings';
import {
  UserFunctionsContainerComponent,
  UserFunctionContainerComponent,
} from './user-functions';
import {
  SchedulesListContainerComponent
} from './schedules';
import { ScheduleContainerComponent } from './schedules/containers/schedule-container';

export const pipelineModuleRoutes: Route[] = [
  {
    path: KE_ROOT_ROUTE,
    loadChildren: () =>
      import('./wokflow-list/components/workflow-list/workflow-list.module').then((m) => m.WorkflowListComponentModule),
    canActivate: [AppAuthGuard],
  },
  {
    path: KE_ROOT_ROUTE,
    canActivate: [AppAuthGuard],
    children: [
      {
        path: KE_WORKFLOW_PATH + '/:id',
        component: WorkflowEditorComponent,
      },
      {
        path: KE_DATASOURCES_PATH,
        children: [
          {
            path: '',
            canActivate: [AppAuthGuard],
            data: {
              roles: [PipelinePermission.DatasourcesListAny],
            },
            component: DataSourcesListContainerComponent,
          },
          {
            path: 'create/:type',
            canActivate: [AppAuthGuard],
            data: {
              roles: [PipelinePermission.DatasourcesCreate],
            },
            component: DataSourcesContainerComponent,
          },
          {
            path: 'edit/:id',
            canActivate: [AppAuthGuard],
            data: {
              roles: [
                PipelinePermission.DatasourcesGetAny,
                PipelinePermission.DatasourcesGetOwn,
                PipelinePermission.DatasourcesUpdateAny,
                PipelinePermission.DatasourcesUpdateOwn,
              ],
            },
            component: DataSourcesContainerComponent,
          },
        ],
      },
      {
        path: KE_PRESETS_PATH,
        children: [
          {
            path: '',
            canActivate: [AppAuthGuard],
            data: {
              roles: [PipelinePermission.PresetsList],
            },
            component: WorkflowPresetsListContainerComponent,
          },
          {
            path: 'create',
            canActivate: [AppAuthGuard],
            data: {
              roles: [PipelinePermission.PresetsCreate],
            },
            component: WorkflowPresetsContainerComponent,
          },
          {
            path: 'edit/:id',
            canActivate: [AppAuthGuard],
            data: {
              roles: [
                PipelinePermission.PresetsGetAny,
                PipelinePermission.PresetsUpdateAny,
              ],
            },
            component: WorkflowPresetsContainerComponent,
          },
          {
            path: 'modal',
            component: WorkflowSettingsContainerComponent,
          },
        ],
      },
      {
        path: KE_USER_FUNCTIONS_PATH,
        canActivate: [AppAuthGuard],
        children: [
          {
            path: '',
            canActivate: [AppAuthGuard],
            component: UserFunctionsContainerComponent,
            data: {
              roles: [PipelinePermission.UserFunctionsListAny]
            },
          },
          {
            path: 'create',
            canActivate: [AppAuthGuard],
            component: UserFunctionContainerComponent,
            data: {
              roles: [PipelinePermission.UserFunctionsCreate]
            }
          },
          {
            path: 'edit/:id',
            canActivate: [AppAuthGuard],
            data: {
              roles: [
                PipelinePermission.UserFunctionsUpdateOwn,
                PipelinePermission.UserFunctionsUpdateAny,
                PipelinePermission.UserFunctionsGetAny,
                PipelinePermission.UserFunctionsGetOwn
              ]
            },
            component: UserFunctionContainerComponent
          }
        ]
      },
      {
        path: KE_SCHEDULES_PATH,
        canActivate: [AppAuthGuard],
        children: [
          {
            path: '',
            canActivate: [AppAuthGuard],
            data: {
              roles: [PipelinePermission.SchedulesListAny]
            },
            component: SchedulesListContainerComponent
          },
          {
            canActivate: [AppAuthGuard],
            data: {
              roles: [PipelinePermission.SchedulesCreate]
            },
            path: 'create',
            component: ScheduleContainerComponent
          },
          {
            path: 'edit/:id',
            canActivate: [AppAuthGuard],
            data: [
              PipelinePermission.SchedulesGetAny,
              PipelinePermission.SchedulesUpdateAny,
            ],
            component: ScheduleContainerComponent
          }
        ]
      }
    ],
  },
];
