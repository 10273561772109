import { NgModule } from '@angular/core';
import { provideEntityData, withEffects } from '@ngrx/data';
import { EffectsModule } from '@ngrx/effects';

import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { biChartEngineEntityConfig } from '@selfai-platform/bi-chart-engine';
import {
  biDomainDashboardEntityConfig,
  biDomainFavoriteEntityConfig,
  biDomainFiltersEntityConfig,
  biDomainWidgetEntityConfig,
  biDomainWorkbookEntityConfig,
  biDomainWorkspaceEntityConfig,
} from '@selfai-platform/bi-domain';
import { breadcrumbsMenuEntityConfig } from '@selfai-platform/shell';
import { pipelineEntityConfig } from './aggregation';
import { keCurrentUserEntityConfig } from './ke-current-user';
import {
  keDataSourceAdditionsEntityConfig,
  keDataSourceEntityConfig,
  keDataSourceLibraryEntityConfig,
} from './ke-datasources';
import { keNamespacesEntityConfig, keUserFunctionsEntityConfig, keWorkflowsEntityConfig } from './ke-user-functions';
import { keUserFunctionsTagsEntityConfig } from './ke-user-functions/user-functions-tags.metadata';
import { keWorkflowEntityConfig } from './ke-workflow';
import {
  workflowPresetsEntityConfig,
  workflowSettingsPresetsBindingEntityConfig,
  workflowSettingsPresetsEntityConfig,
} from './ke-workflow-presets';
import { workflowSchedulesEntityConfig } from './ke-workflow-schedules';
import { keSchedulesEntityConfig } from './ke-schedules';
import { keSchedulesWorkflowsEntityConfig } from './ke-schedules/workflows.metadata';
import { keSchedulesPresetsEntityConfig } from './ke-schedules/presets.metadata';

@NgModule({
  imports: [
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    // StoreDevtoolsModule must be imported after StoreModule.forRoot else it will not work
    StoreDevtoolsModule.instrument({
      logOnly: false,
      connectInZone: true,
    }),
  ],
  providers: [
    // all entity metadata must be registered in one provideEntityData
    provideEntityData(
      {
        entityMetadata: {
          ...biChartEngineEntityConfig.entityMetadata,
          ...biDomainWidgetEntityConfig.entityMetadata,
          ...biDomainDashboardEntityConfig.entityMetadata,
          ...biDomainFiltersEntityConfig.entityMetadata,
          ...biDomainWorkbookEntityConfig.entityMetadata,
          ...breadcrumbsMenuEntityConfig.entityMetadata,
          ...pipelineEntityConfig.entityMetadata,
          ...biDomainFavoriteEntityConfig.entityMetadata,
          ...biDomainWorkspaceEntityConfig.entityMetadata,
          ...keDataSourceEntityConfig.entityMetadata,
          ...keDataSourceAdditionsEntityConfig.entityMetadata,
          ...keCurrentUserEntityConfig.entityMetadata,
          ...keDataSourceLibraryEntityConfig.entityMetadata,
          ...workflowPresetsEntityConfig.entityMetadata,
          ...workflowSettingsPresetsEntityConfig.entityMetadata,
          ...workflowSchedulesEntityConfig.entityMetadata,
          ...workflowSettingsPresetsBindingEntityConfig.entityMetadata,
          ...keUserFunctionsEntityConfig.entityMetadata,
          ...keUserFunctionsTagsEntityConfig.entityMetadata,
          ...keNamespacesEntityConfig.entityMetadata,
          ...keWorkflowsEntityConfig.entityMetadata,
          ...keWorkflowEntityConfig.entityMetadata,
          ...keSchedulesEntityConfig.entityMetadata,
          ...keSchedulesWorkflowsEntityConfig.entityMetadata,
          ...keSchedulesPresetsEntityConfig.entityMetadata,
        },
        pluralNames: {
          ...biChartEngineEntityConfig.pluralNames,
          ...biDomainFiltersEntityConfig.pluralNames,
        },
      },
      withEffects(),
    ),
  ],
})
export class SelfaiStoreModule {}
