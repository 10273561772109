import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { InputTextModule } from 'primeng/inputtext';

import { MessageModule } from 'primeng/message';
import { FormFieldComponentModule } from '../../../../../form-field/form-field.module';
import { DataSourceSelectorComponent } from '../../../data-source-selector';
import { FormArraySortActionButtonsComponent } from '../../../form-array-sort-action-buttons';
import { CreateDataFrameColumnMapingComponent } from '../create-data-frame-column-maping';
import { CreateDataFrameComponentService } from '../create-data-frame-component.service';

@Component({
  selector: 'selfai-platform-create-data-frame',
  templateUrl: './create-data-frame.component.html',
  styleUrl: './create-data-frame.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    FormFieldComponentModule,
    DataSourceSelectorComponent,
    InputTextModule,
    ReactiveFormsModule,
    TranslateModule,
    CreateDataFrameColumnMapingComponent,
    FormArraySortActionButtonsComponent,
    ButtonModule,
    DividerModule,
    MessageModule,
  ],
})
export class CreateDataFrameComponent {
  form = this.createDataFrameComponentService.form;
  nodeState$ = this.createDataFrameComponentService.nodeState$;

  constructor(private readonly createDataFrameComponentService: CreateDataFrameComponentService) {}

  onSubmit(): void {
    this.createDataFrameComponentService.onSubmit();
  }
}
