import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory, EntityDataService } from '@ngrx/data';
import { KE_WORKFLOW_SCHEDULES_ENTITY_NAME } from '@selfai-platform/store';
import { WorkflowSettingsSchedules } from '../interface/workflow-settings-schedules.interface';
import { WorkflowSettingsSchedulesService } from './workflow-settings-schedules.service';

@Injectable({ providedIn: 'root' })
export class WorkflowSettingsSchedulesStore extends EntityCollectionServiceBase<WorkflowSettingsSchedules> {
  constructor(
    public entityDataService: EntityDataService,
    public workflowSettingsSchedulesService: WorkflowSettingsSchedulesService,
    public serviceElementsFactory: EntityCollectionServiceElementsFactory,
  ) {
    entityDataService.registerService(KE_WORKFLOW_SCHEDULES_ENTITY_NAME, workflowSettingsSchedulesService);
    super(KE_WORKFLOW_SCHEDULES_ENTITY_NAME, serviceElementsFactory);
  }
}
