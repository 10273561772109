import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DialogService as PrimeDialogService } from 'primeng/dynamicdialog';
import { DialogService } from '@selfai-platform/shell';
import { CubeEditResult, CubeWorkflowData, GraphNode } from '@selfai-platform/pipeline-common';

@Injectable()
export class CubeDialogManagementService {
  public zIndex = 1050;
  public collapsedDialogs$: BehaviorSubject<CubeWorkflowData[]> = new BehaviorSubject<CubeWorkflowData[]>([]);

  constructor(
    private readonly shellDialogService: DialogService<CubeEditResult, CubeWorkflowData>,
    private readonly primeDialogService: PrimeDialogService,
  ) {}

  public isExist(nodeId: string): boolean {
    let exist = false;
    this.primeDialogService.dialogComponentRefMap.forEach((component) => {
      if (component.instance.config.data['selectedNode'].id === nodeId) {
        exist = true;
      }
    });
    return exist;
  }

  public collapse(nodeId: string): void {
    const tabs = this.collapsedDialogs$.getValue();
    let node;
    this.primeDialogService.dialogComponentRefMap.forEach((componentRef, ref) => {
      if (this.primeDialogService.dialogComponentRefMap.get(ref)?.instance.config.data.selectedNode.id === nodeId) {
        node = this.primeDialogService.dialogComponentRefMap.get(ref)?.instance.config.data;
      }
    });
    tabs.push(node);
    this.setFocus(nodeId, true);
    this.collapsedDialogs$.next([...tabs]);
  }

  public expand(nodeId: string): void {
    this.setFocus(nodeId);
    const tabs = this.collapsedDialogs$.getValue();
    this.collapsedDialogs$.next(tabs.filter((dialog) => dialog.selectedNode.id !== nodeId));
  }

  public closeCollapsed(nodeId: string): void {
    let tabs = this.collapsedDialogs$.getValue();
    tabs = tabs.filter(tab => tab.selectedNode.id !== nodeId);
    this.primeDialogService.dialogComponentRefMap.forEach((componentRef, ref) => {
      if (this.primeDialogService.dialogComponentRefMap.get(ref)?.instance.config.data.selectedNode.id === nodeId) {
        ref.close();
      }
    });
    this.collapsedDialogs$.next([...tabs]);
  }

  public setFocus(nodeId: string, backward: boolean = false): void {
    this.primeDialogService.dialogComponentRefMap.forEach((dialog, ref) => {
      const id = dialog.instance.config.data.selectedNode.id;
      if (id === nodeId) {
        const dialogChildren = dialog.location.nativeElement.children[0];
        // backward = true - Hide dialog behind application
        if (backward) {
          dialogChildren.style.zIndex = (-this.zIndex--).toString();
          dialogChildren.children[0].style.zIndex = (-this.zIndex--).toString();
          this.shellDialogService.currentDialogRef = this.shellDialogService.dialogRefs[this.shellDialogService.dialogRefs.length - 1];
        } else {
          // Change zIndex of Layout
          dialogChildren.style.zIndex = (this.zIndex++).toString();
          // Change zIndex of layered DialogComponent by PrimeNG
          dialogChildren.children[0].style.zIndex = (this.zIndex++).toString();
          this.shellDialogService.currentDialogRef = ref;
        }
      }
    });
  }
}
