import { Directive, Input } from '@angular/core';
import { TemplateNameDirective } from '@selfai-platform/shared';
import { DataListViewTemplateName } from '../constants';

type CustomFieldValue<TFields> = `fieldValue.${keyof TFields extends string ? keyof TFields : never}`;
type TemplateNames<TFields> = DataListViewTemplateName | CustomFieldValue<TFields>;

type DataType<
  TData extends DataListViewTemplateName | CustomFieldValue<TFields>,
  TFields extends object,
> = TData extends 'fieldValue' ? { fieldCode: keyof TFields } : undefined;

@Directive({
  selector: '[selfaiPlatformDataListViewTemplateName]',
  standalone: true,
})
export class DataListViewTemplateNameDirective<TFields extends object> extends TemplateNameDirective<
  TemplateNames<TFields>,
  DataType<TemplateNames<TFields>, TFields>
> {
  @Input({ required: true, alias: 'selfaiPlatformDataListViewTemplateName' })
  override selfaiPlatformTemplateName: TemplateNames<TFields>;
}
