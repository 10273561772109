import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { WorkflowInfo } from '@selfai-platform/pipeline-common';
import { HistoryBackService } from '@selfai-platform/shared';
import { KE_ROOT_ROUTE, KE_SCHEDULES_PATH } from '@selfai-platform/shell';
import { Observable, take } from 'rxjs';
import { UserFunctionComponent } from '../../../user-functions/components';
import { WorkflowPresets } from '../../../workflow-presets';
import { ScheduleComponent } from '../../components/schedule/schedule.component';
import { SchedulesListItem } from '../../models';
import { SchedulesListStore } from '../../store';
import { SchedulesPresetsListStore } from '../../store/presets-list.store';
import { SchedulesWorkflowsListStore } from '../../store/workflows-list.store';

@Component({
  selector: 'selfai-platform-schedule-container',
  templateUrl: './schedule-container.component.html',
  styleUrls: ['./schedule-container.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ScheduleComponent, AsyncPipe, UserFunctionComponent],
})
export class ScheduleContainerComponent implements OnInit {
  public schedules$ = this.schedulesListStore.entities$ as Observable<SchedulesListItem[]>;
  public workflows$ = this.schedulesWorkflowsStore.entities$ as Observable<WorkflowInfo[]>;
  public presets$ = this.schedulesPresetsListStore.entities$ as Observable<WorkflowPresets[]>;
  public loading$ = this.schedulesWorkflowsStore.loading$ as Observable<boolean>;

  public id: string = this.route?.snapshot?.params['id'];
  public editMode = !!this.id;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly schedulesListStore: SchedulesListStore,
    private readonly schedulesWorkflowsStore: SchedulesWorkflowsListStore,
    private readonly schedulesPresetsListStore: SchedulesPresetsListStore,
    private readonly history: HistoryBackService,
  ) {}

  public ngOnInit(): void {
    if (this.editMode) {
      this.schedulesListStore.getByKey(this.id);
    }
    this.schedulesListStore.getAll();
    this.schedulesWorkflowsStore.getAll();
    this.schedulesPresetsListStore.getAll();
  }

  public onSubmit(schedule: SchedulesListItem): void {
    if (this.editMode) {
      this.schedulesListStore
        .update(schedule)
        .pipe(take(1))
        .subscribe(() => this.onBack());
    } else {
      this.schedulesListStore
        .add(schedule)
        .pipe(take(1))
        .subscribe(() => this.onBack());
    }
  }

  public onBack(): void {
    this.history.back(['/', KE_ROOT_ROUTE, KE_SCHEDULES_PATH]);
  }
}
