import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PipelineConfigService } from '@selfai-platform/shared';
import { map, Observable, tap } from 'rxjs';
import { API_RESPONSE_MESSAGES } from '../constants';
import {
  FunctionScope,
  FunctionTag,
  IFunctionImportMessages,
  Namespace,
  UserFunctionApi,
  UserFunctionUpdateApi,
} from '../models';
import { FunctionsResponseCallbacksService } from './functions-response-callbacks.service';

@Injectable({
  providedIn: 'root',
})
export class UserFunctionsApiService {
  private readonly config = this.pipelineConfigService.getConfig();
  private readonly apiUrl = `${this.config.hosts.api}/${this.config.versions.url}`;
  private readonly userFunctionsPath = 'functions';
  private readonly tagsPath = 'tags';
  private readonly namespacesPath = 'namespaces';

  constructor(
    private readonly http: HttpClient,
    private readonly pipelineConfigService: PipelineConfigService,
    private readonly functionsResponseCallbackService: FunctionsResponseCallbacksService,
  ) {}

  loadList(): Observable<UserFunctionApi[]> {
    return this.http.post<UserFunctionApi[]>(`${this.apiUrl}/${this.userFunctionsPath}/list`, {});
  }

  loadTagsList(): Observable<FunctionTag[]> {
    return this.http.get<FunctionTag[]>(`${this.apiUrl}/${this.userFunctionsPath}/${this.tagsPath}/list`);
  }

  loadNamespacesList(): Observable<Namespace[]> {
    return this.http.get<Namespace[]>(`${this.apiUrl}/${this.userFunctionsPath}/${this.namespacesPath}/list`);
  }

  loadUserFunction(id: string): Observable<UserFunctionApi> {
    return this.http.get<UserFunctionApi>(`${this.apiUrl}/${this.userFunctionsPath}/${id}`);
  }

  addUserFunction(userFunctionParams: UserFunctionApi): Observable<UserFunctionApi> {
    return this.http
      .post(`${this.apiUrl}/${this.userFunctionsPath}`, userFunctionParams, {
        headers: {
          Accept: 'text/plain',
        },
        responseType: 'text',
      })
      .pipe(map((id) => ({ ...userFunctionParams, id }) as UserFunctionApi));
  }

  updateUserFunction(data: UserFunctionApi): Observable<UserFunctionApi> {
    return this.http
      .put(`${this.apiUrl}/${this.userFunctionsPath}/${data.id}`, this.normalizeUpdateData(data), {
        headers: {
          Accept: 'text/plain',
        },
        responseType: 'text',
      })
      .pipe(map(() => data));
  }

  deleteUserFunction(id: string): Observable<string> {
    return this.http.delete<string>(`${this.apiUrl}/${this.userFunctionsPath}/${id}`);
  }

  exportUserFunctions(ids: string[]): Observable<unknown> {
    return this.http.post(`${this.apiUrl}/${this.userFunctionsPath}/export`, { ids }).pipe(
      tap({
        next: () =>
          this.functionsResponseCallbackService.handleNextCallback(API_RESPONSE_MESSAGES.EXPORT_SUCCESS, false),
        error: ({ error }) =>
          this.functionsResponseCallbackService.handleErrorCallback(
            API_RESPONSE_MESSAGES.EXPORT_ERROR,
            JSON.stringify(error),
          ),
      }),
    );
  }

  importUserFunctions(
    userFunctions: UserFunctionApi[],
    scopes: FunctionScope[],
    override: boolean = false,
  ): Observable<IFunctionImportMessages[]> {
    let url = `${this.apiUrl}/${this.userFunctionsPath}/import?override=${override}`;
    if (scopes?.length) {
      const scopesIds = scopes.map((scope) => scope.scopeId).join(',');
      url = url + '&scopes=' + scopesIds;
    }
    return this.http.post<IFunctionImportMessages[]>(url, userFunctions).pipe(
      tap({
        next: (messages) => {
          if (messages.find((message) => message.success)) {
            this.functionsResponseCallbackService.handleNextCallback(API_RESPONSE_MESSAGES.IMPORT_SUCCESS, false);
          }
        },
        error: ({ error }) =>
          this.functionsResponseCallbackService.handleErrorCallback(
            API_RESPONSE_MESSAGES.IMPORT_ERROR,
            JSON.stringify(error),
          ),
      }),
    );
  }

  cloneUserFunction(id: string, newFunctionName: string): Observable<string> {
    return this.http.post<string>(`${this.apiUrl}/${this.userFunctionsPath}/${id}/clone`, newFunctionName).pipe(
      tap({
        next: () =>
          this.functionsResponseCallbackService.handleNextCallback(API_RESPONSE_MESSAGES.CLONE_SUCCESS, false),
        error: ({ error }) =>
          this.functionsResponseCallbackService.handleErrorCallback(
            API_RESPONSE_MESSAGES.CLONE_ERROR,
            JSON.stringify(error),
          ),
      }),
    );
  }

  deleteUserFunctionTag(id: string): Observable<string> {
    return this.http.delete<string>(`${this.apiUrl}/${this.userFunctionsPath}/${this.tagsPath}/${id}`);
  }

  private normalizeUpdateData(data: UserFunctionApi): UserFunctionUpdateApi {
    return {
      ...data,
      scopes: data.scopes.filter((scope) => scope.scopeId !== null),
      tags: data.tags.map((tag) => (typeof tag === 'object' ? tag : { name: tag })),
    };
  }
}
