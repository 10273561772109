import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory, EntityDataService } from '@ngrx/data';
import { KE_USER_FUNCTIONS_TAGS_ENTITY_NAME } from '@selfai-platform/store';
import { FunctionTag } from '../models';
import { UserFunctionsTagsService } from './user-functions-tags.service';

@Injectable({ providedIn: 'root' })
export class UserFunctionsTagsStore extends EntityCollectionServiceBase<FunctionTag> {
  constructor(
    public entityDataService: EntityDataService,
    public userFunctionTagsDataService: UserFunctionsTagsService,
    public serviceElementsFactory: EntityCollectionServiceElementsFactory,
  ) {
    entityDataService.registerService(KE_USER_FUNCTIONS_TAGS_ENTITY_NAME, userFunctionTagsDataService);
    super(KE_USER_FUNCTIONS_TAGS_ENTITY_NAME, serviceElementsFactory);
  }

  [name: string]: unknown;
}
