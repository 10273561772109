@if (hasParameters) {
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <selfai-platform-form-field
      label="{{ 'workflow.cubes.enrichment-tool-batch.field-label.jdbc-driver' | translate }}"
    >
      <input
        type="text"
        class="w-full"
        pInputText
        [formControl]="form.controls.jdbcDriver"
        placeholder="{{ 'workflow.cubes.enrichment-tool-batch.placeholder.jdbc-driver' | translate }}"
      />
    </selfai-platform-form-field>

    <selfai-platform-form-field label="{{ 'workflow.cubes.enrichment-tool-batch.field-label.jdbc-url' | translate }}">
      <input
        type="text"
        class="w-full"
        pInputText
        [formControl]="form.controls.jdbcUrl"
        placeholder="{{ 'workflow.cubes.enrichment-tool-batch.placeholder.jdbc-url' | translate }}"
      />
    </selfai-platform-form-field>

    <selfai-platform-form-field label="{{ 'workflow.cubes.enrichment-tool-batch.field-label.column' | translate }}">
      <selfai-platform-edit-selection-field [formControl]="form.controls.column"></selfai-platform-edit-selection-field>
    </selfai-platform-form-field>

    <selfai-platform-form-field label="{{ 'workflow.cubes.enrichment-tool-batch.field-label.sql-query' | translate }}">
      <selfai-platform-code-editor
        class="p-inputtext"
        language="sparksql"
        [formControl]="form.controls.sqlQuery"
      ></selfai-platform-code-editor>
    </selfai-platform-form-field>
  </form>
} @else {
  <p-message text="{{ 'workflow.cubes.enrichment-tool-batch.no-parameters' | translate }}" severity="error"></p-message>
}
