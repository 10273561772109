import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AlertService, PipelineConfigService } from '@selfai-platform/shared';
import { Observable, tap } from 'rxjs';
import { ISchedule, SchedulesListItem } from '../models';
import { Update } from '@ngrx/entity/src/models';

@Injectable({
  providedIn: 'root',
})
export class SchedulesApiService {
  private readonly config = this.pipelineConfigService.getConfig();
  private readonly schedulesPath = 'schedulingmanager';
  private readonly apiUrl = `${this.config.hosts.api}/${this.schedulesPath}/${this.config.versions.url}`;
  private readonly workflowSchedulesPath = 'workflow-schedules';

  constructor(
    private readonly http: HttpClient,
    private readonly pipelineConfigService: PipelineConfigService,
    private readonly alertService: AlertService,
  ) {}

  public loadList(): Observable<ISchedule[]> {
    return this.http.get<ISchedule[]>(`${this.apiUrl}/${this.workflowSchedulesPath}/`);
  }

  public getSchedule(id: string): Observable<ISchedule> {
    return this.http.get<ISchedule>(`${this.apiUrl}/${this.workflowSchedulesPath}/${id}`);
  }

  public addSchedule(schedule: SchedulesListItem): Observable<ISchedule> {
    return this.http.put<SchedulesListItem>(`${this.apiUrl}/${this.workflowSchedulesPath}/${schedule.id}`, schedule).pipe(
      tap({
        next: () => {
          this.alertService.success(`Schedule added`);
        },
        error: () => {
          this.alertService.error(`Create Error`);
        },
      }),
    );
  }

  public updateSchedule(updateSchedule: Update<SchedulesListItem>): Observable<ISchedule> {
    return this.http
      .put<SchedulesListItem>(`${this.apiUrl}/${this.workflowSchedulesPath}/${updateSchedule.changes.id}`, updateSchedule.changes)
      .pipe(
        tap({
          next: () => {
            this.alertService.success(`Schedule changed`);
          },
          error: () => {
            this.alertService.error(`Edit error`);
          },
        }),
      );
  }

  public deleteSchedule(id: string): Observable<string> {
    return this.http.delete<string>(`${this.apiUrl}/${this.workflowSchedulesPath}/${id}`).pipe(
      tap({
        next: () => {
          this.alertService.success(`Successful deleted schedule`);
        },
        error: () => {
          this.alertService.error(`Delete error`);
        },
      }),
    );
  }
}
