import { FormArray, FormControl, FormGroup } from '@angular/forms';

export const TypeOfAdditions = ['none', 'key-value', 'text'] as const;
export type TypeOfAdditions = typeof TypeOfAdditions[number];

export const TypeOfValue = ['string', 'numeric', 'boolean'];
export type TypeOfValue = typeof TypeOfValue[number];

export interface AdditionsFormGroup {
  type: FormControl<TypeOfAdditions>;
  propertiesString: FormControl<string | null>;
  propertiesArray: FormArray<FormGroup<AdditionsKeyValueFormGroup>>;
}

export interface AdditionsKeyValueFormGroup {
  key: FormControl<string | null>;
  type: FormControl<TypeOfValue>;
  value: FormControl<string | number | boolean | null>;
}
