import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import {
  CreateDataColumnType,
  CreateDataFrameColumnMappingFormGroup,
  CreateDataFrameFormGroup,
} from '@selfai-platform/pipeline-common';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { Table, TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { UsedInPanelComponent, UsedInPipe } from '../../../../../common/used-in-panel';
import { FormFieldComponentModule } from '../../../../../form-field/form-field.module';
import { WorkflowReportDomainService } from '../../../../../wokflow-list';
import { CreateDataFrameComponentService } from '../create-data-frame-component.service';

@Component({
  selector: 'selfai-platform-create-data-frame-column-maping',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    DropdownModule,
    InputTextModule,
    TableModule,
    TooltipModule,
    FormFieldComponentModule,
    ReactiveFormsModule,
    UsedInPanelComponent,
    UsedInPipe,
    ButtonModule,
  ],

  templateUrl: './create-data-frame-column-maping.component.html',
  styleUrl: './create-data-frame-column-maping.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateDataFrameColumnMapingComponent implements OnInit {
  @ViewChild('tableRef', { static: false, read: Table }) tableRef: Table;

  get itemsFormArray(): CreateDataFrameFormGroup['columnMapping'] {
    return this.createDataFrameComponentService.form.controls.columnMapping;
  }

  get items(): FormGroup<CreateDataFrameColumnMappingFormGroup>[] {
    return this.itemsFormArray.controls;
  }

  get nodeId(): string {
    return this.createDataFrameComponentService.nodeId;
  }

  dataTypeItems: CreateDataColumnType[] = Object.values(CreateDataColumnType);

  executionReportIsLoading$ = this.workflowReportDomainService.getExecutionReportLoading();

  private readonly createDataFrameComponentService = inject(CreateDataFrameComponentService);
  private readonly destroyRef = inject(DestroyRef);
  private readonly cdr = inject(ChangeDetectorRef);

  constructor(private readonly workflowReportDomainService: WorkflowReportDomainService) {}

  ngOnInit(): void {
    // When items reset in another component this component doesn't know about it
    this.itemsFormArray.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.cdr.markForCheck();
    });
  }

  filter(event: Event): void {
    this.tableRef.filterGlobal((event.target as HTMLInputElement).value, 'contains');
  }

  onRowReorder() {
    const items = (this.tableRef.value as FormGroup<CreateDataFrameColumnMappingFormGroup>[]).map((item) =>
      item.getRawValue(),
    );
    this.itemsFormArray.setValue(items);
  }

  copyItem(index: number): void {
    const item = this.items[index].getRawValue();

    if (item) {
      this.createDataFrameComponentService.addColumnFormGroup(item);
    }
  }

  removeItem(index: number): void {
    this.itemsFormArray.removeAt(index);
  }

  addItem(): void {
    this.createDataFrameComponentService.addColumnFormGroup();
  }
}
