import { inject, Injectable } from '@angular/core';
import { DatasourceType } from '@selfai-platform/pipeline-common';
import { PipelineConfigService } from '@selfai-platform/shared';
import { Observable, of } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import {
  DataSourceApi,
  DataSourceConnectionParams,
  DataSourceLibraryParams,
  DataSourceParamsName,
  DataSourceParamsType,
} from '../../model';
import { DataSourceFormValues } from '../../model/data-source-form.model';
import { DataSourceDomainService } from '../../services';
import { DataSourceFormService } from './data-sources-form.service';

@Injectable()
export class DataSourceComponentService {
  private dataSourceFormService: DataSourceFormService = inject(DataSourceFormService);
  private dataSourceDomainService: DataSourceDomainService = inject(DataSourceDomainService);
  private pipelineConfig = inject(PipelineConfigService).getConfig();

  saveDataSource(dataSourceData: Omit<DataSourceApi, 'params'>): Observable<DataSourceApi> {
    const form = this.dataSourceFormService.form;
    if (form.valid) {
      if (dataSourceData.id) {
        return this.dataSourceDomainService.updateDataSource(
          this.normalizeFormValuesToUpdateDataSource(dataSourceData, form.value as DataSourceFormValues),
        );
      } else {
        return this.dataSourceDomainService.createDataSource(
          this.normalizeFormValuesToCreateDataSource(dataSourceData, form.value as DataSourceFormValues),
        );
      }
    }
    return of(null);
  }

  normalizeParamsToFormValues(params: DataSourceParamsType): DataSourceFormValues {
    return {
      name: params.name,
      visibility: params.visibility,
      datasourceType: params.datasourceType,
      properties: params.properties,
      params: this.normalizeConnectionParamsToFormValues(
        params.datasourceType,
        params[this.mapParamName(params.datasourceType)],
      ),
    };
  }

  private normalizeConnectionParamsToFormValues(
    datasourceType: DatasourceType,
    connectionParams: DataSourceConnectionParams,
  ): DataSourceConnectionParams {
    if (this.isLibraryDataSource(datasourceType, connectionParams)) {
      const { libraryPath } = connectionParams;
      const { libraryPrefix } = this.pipelineConfig;

      return {
        ...connectionParams,
        libraryPath: libraryPath.startsWith(libraryPrefix) ? libraryPath.slice(libraryPrefix.length) : libraryPath,
      };
    }

    return connectionParams;
  }

  private isLibraryDataSource(
    datasourceType: DatasourceType,
    connectionParams: DataSourceConnectionParams,
  ): connectionParams is DataSourceLibraryParams['libraryFileParams'] {
    return datasourceType === DatasourceType.LIBRARY;
  }

  private normalizeFormValuesToCreateDataSource(
    dataSourceData: Omit<DataSourceApi, 'params' | 'id' | 'ownerId' | 'ownerName'>,
    formValues: DataSourceFormValues,
  ): Omit<DataSourceApi, 'ownerId' | 'ownerName'> {
    return {
      ...dataSourceData,
      id: uuidv4(),
      params: this.normalizeFormValuesToParams(formValues),
    };
  }

  private normalizeFormValuesToUpdateDataSource(
    dataSourceData: Omit<DataSourceApi, 'params'>,
    formValues: DataSourceFormValues,
  ): DataSourceApi {
    return {
      ...dataSourceData,
      params: this.normalizeFormValuesToParams(formValues),
    };
  }

  private normalizeFormValuesToParams(formValues: DataSourceFormValues): DataSourceParamsType {
    const params = {
      name: formValues.name,
      visibility: formValues.visibility,
      datasourceType: formValues.datasourceType,
      properties: formValues.properties,
      [this.mapParamName(formValues.datasourceType)]: formValues.params,
    };

    return this.normalizeDataSourceParamsForApi(params);
  }

  private normalizeDataSourceParamsForApi(params: DataSourceParamsType): DataSourceParamsType {
    if (this.isDataSourceParamsLibraryType(params)) {
      const { libraryPrefix } = this.pipelineConfig;

      return {
        ...params,
        libraryFileParams: {
          ...params.libraryFileParams,
          libraryPath: `${libraryPrefix}${params.libraryFileParams.libraryPath}`,
        },
      };
    }

    return params;
  }

  private isDataSourceParamsLibraryType(params: DataSourceParamsType): params is DataSourceLibraryParams {
    return params.datasourceType === 'libraryFile';
  }

  private mapParamName(dataSourceType: DatasourceType): DataSourceParamsName {
    switch (dataSourceType) {
      case DatasourceType.LIBRARY:
        return DataSourceParamsName.LIBRARY;
      case DatasourceType.S3:
        return DataSourceParamsName.S3;
      case DatasourceType.KAFKA:
        return DataSourceParamsName.KAFKA;
      case DatasourceType.HDFS:
        return DataSourceParamsName.HDFS;
      case DatasourceType.GOOGLE_SP:
        return DataSourceParamsName.GOOGLE_SP;
      case DatasourceType.EX_FILE:
        return DataSourceParamsName.EX_FILE;
      default:
      case DatasourceType.JDBC:
        return DataSourceParamsName.JDBC;
    }
  }
}
