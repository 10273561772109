import { EntityMetadataMap } from '@ngrx/data';

export const KE_WORKFLOW_ENTITY_NAME = 'Ke Workflow';

const entityMetadata: EntityMetadataMap = {
  [KE_WORKFLOW_ENTITY_NAME]: {},
};

export const keWorkflowEntityConfig = {
  entityMetadata,
};
