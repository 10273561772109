<ng-container *ngIf="operationLoading; else resultTemplate">
  <p-skeleton width="100%" height="100%" shape="rectangle"></p-skeleton>
</ng-container>

<ng-template #resultTemplate>
  <selfai-platform-code-editor
    *ngIf="language; else errorTemplate"
    [language]="language === 'sql' ? 'sparksql' : language"
    [fields]="fieldsFromExecutionReport$ | async"
    [canMaximized]="false"
    [(ngModel)]="codeText"
  ></selfai-platform-code-editor>
</ng-template>

<ng-template #errorTemplate>
  <p-message class="block" severity="error" text="Error. Try again later"></p-message>
</ng-template>
