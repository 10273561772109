import { animate, state, style, transition, trigger } from '@angular/animations';
import {
  ChangeDetectionStrategy,
  Component,
  Injector,
  OnInit,
  Renderer2,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  Expression,
  ExpressionItem,
  ExpressionItemFormGroup,
  WorkflowExpressionData,
} from '@selfai-platform/pipeline-common';
import { DestroyService } from '@selfai-platform/shared';
import { Table } from 'primeng/table';
import { filter, take, takeUntil } from 'rxjs';
import { DialogHelperService } from '../../../../dialog';
import { normalizeToLegacyDataExpressionTool } from '../../../converters/expression-tool.normalizer';
import { CubeDialogManagementService } from '../../../services/cube-dialog-management.service';
import { SaveConfirmationService } from '../../../services/save-confirmation.service';
import { SelectionStoreService } from '../../../services/selection-store.service';
import { AbstractCubeDialogFormWithTableComponent } from '../../abstract-cube-dialog-form-with-table.component';
import { DialogHeaderService } from '../../dialog-header/dialog-header.service';
import { ExpressionToolComponentService } from './expression-tool-component.service';

@Component({
  selector: 'selfai-platform-expression-tool',
  templateUrl: './expression-tool.component.html',
  styleUrls: ['./expression-tool.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    DestroyService,
    DialogHelperService,
    SelectionStoreService,
    SaveConfirmationService,
    ExpressionToolComponentService,
  ],
  animations: [
    trigger('rowExpansionTrigger', [
      state(
        'void',
        style({
          'max-height': '0px',
        }),
      ),
      state(
        'active',
        style({
          'max-height': '1000px',
        }),
      ),
      transition('* <=> *', animate('1000ms')),
    ]),
  ],
})
export class ExpressionToolComponent extends AbstractCubeDialogFormWithTableComponent implements OnInit {
  override itemsMap = new Map<string, FormGroup<ExpressionItemFormGroup>>();

  override initialItem: ExpressionItem = {
    id: '',
    resultColumn: '',
    type: {
      kind: Expression.EXPRESSION,
      sourceValue1: null,
      sourceValue2: null,
      sqlExpression: null,
      divisionZeroStrategy: null,
    },
  };

  @ViewChild('tableRef', { static: false, read: Table }) tableRef!: Table;

  get dataForWorkflow(): WorkflowExpressionData {
    return normalizeToLegacyDataExpressionTool(
      (this.items as FormGroup<ExpressionItemFormGroup>[]).map(({ value }) => value as ExpressionItem),
    );
  }

  constructor(
    private readonly expressionToolComponentService: ExpressionToolComponentService,
    injector: Injector,
    private readonly dialogHeaderService: DialogHeaderService,
    private readonly cubeDialogManagementService: CubeDialogManagementService,
    private readonly viewContainerRef: ViewContainerRef,
    private readonly renderer: Renderer2,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.expressionToolComponentService
      .getItemFormGroups()
      .pipe(take(1), filter(Boolean), takeUntil(this.destroy$))
      .subscribe((items) => {
        items.forEach((formGroup) => this.itemsMap.set(formGroup.controls.id.value, formGroup));
        this.markFormAsInitialized();
      });
    this.dialogHeaderService.initCustomHeaderComponent(
      this.nodeId,
      'Expression Tool',
      this.viewContainerRef,
      this.renderer,
      this.closeDialog.bind(this),
      this.onClickSave.bind(this),
    );
    setTimeout(() => {
      this.cubeDialogManagementService.setFocus(this.nodeId);
    });
  }

  filter(event: Event): void {
    this.tableRef.filterGlobal((event.target as HTMLInputElement).value, 'contains');
  }

  mapItemToFormGroup(item: ExpressionItem): FormGroup<ExpressionItemFormGroup> {
    return this.expressionToolComponentService.mapItemToFormGroup(item);
  }
}
