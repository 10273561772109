import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { KE_WORKFLOW_ENTITY_NAME } from '@selfai-platform/store';
import { Observable } from 'rxjs';
import { type WorkflowState } from '../../stores';
import { WorkflowDetailService } from './workflow-detail.service';

@Injectable({ providedIn: 'root' })
export class WorkflowDataService extends DefaultDataService<WorkflowState> {
  constructor(
    http: HttpClient,
    httpUrlGenerator: HttpUrlGenerator,
    private readonly workflowDetailService: WorkflowDetailService,
  ) {
    super(KE_WORKFLOW_ENTITY_NAME, http, httpUrlGenerator);
  }

  override getById(workbookId: string): Observable<WorkflowState> {
    return this.workflowDetailService.loadWorkflow(workbookId);
  }
}
