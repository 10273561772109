import { Injectable } from '@angular/core';
import {
  PermissionService,
  PipelinePermissionEntity,
  PresetsPermissionAction,
} from '@selfai-platform/shared';
import { Observable} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WorkflowPresetsPermissionService {
  constructor(private readonly permissionService: PermissionService) {}

  public checkPermission(permissionAction: PresetsPermissionAction, isOwner?: boolean): Observable<boolean> {
    return this.permissionService.checkPermission({
      entity: PipelinePermissionEntity.Presets,
      isOwner,
      action: permissionAction,
    });
  }
}
