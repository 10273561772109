import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { KE_USER_FUNCTIONS_TAGS_ENTITY_NAME } from '@selfai-platform/store';
import { map, Observable, tap } from 'rxjs';
import { API_RESPONSE_MESSAGES } from '../constants';
import { FunctionTag } from '../models';
import { FunctionsResponseCallbacksService, UserFunctionsApiService } from '../services';

@Injectable({
  providedIn: 'root',
})
export class UserFunctionsTagsService extends DefaultDataService<FunctionTag> {
  constructor(
    public override http: HttpClient,
    public override httpUrlGenerator: HttpUrlGenerator,
    private readonly userFunctionsApiService: UserFunctionsApiService,
    private readonly responseCallbacksService: FunctionsResponseCallbacksService,
  ) {
    super(KE_USER_FUNCTIONS_TAGS_ENTITY_NAME, http, httpUrlGenerator);
  }

  override getAll(): Observable<FunctionTag[]> {
    return this.userFunctionsApiService
      .loadTagsList()
      .pipe(map((items) => items.map((item) => ({ ...item, name: item.name }))));
  }

  override delete(id: string): Observable<string> {
    return this.userFunctionsApiService.deleteUserFunctionTag(id).pipe(
      tap({
        next: () => this.responseCallbacksService.handleNextCallback(API_RESPONSE_MESSAGES.DELETE_TAG_SUCCESS, false),
        error: ({ error }) =>
          this.responseCallbacksService.handleErrorCallback(API_RESPONSE_MESSAGES.DELETE_TAG_ERROR, error),
      }),
    );
  }
}
