import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'selfai-bi-dashbord-preview-image',
  templateUrl: './dashbord-preview-image.component.html',
  styleUrls: ['./dashbord-preview-image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class DashbordPreviewImageComponent implements OnInit {
  imgSrc = 'assets/bi/images/img_board_default2.png';
  defaultSrc = true;

  @Input() imageUrl: string | undefined;

  ngOnInit(): void {
    if (this.imageUrl) {
      const date = Date.now();
      this.imgSrc = '/api/images/load/url?url=' + this.imageUrl + '/thumbnail?' + date;
      this.defaultSrc = false;
    }
  }
}
