<p-table
  class="table"
  dataKey="controls.id.value"
  #tableRef
  responsiveLayout="scroll"
  [value]="items"
  (onRowReorder)="onRowReorder()"
  [globalFilterFields]="['controls.columnName.value']"
  [loading]="executionReportIsLoading$ | async"
>
  <ng-template pTemplate="caption">
    <div class="flex">
      <span class="p-input-icon-left">
        <i class="pi pi-search"></i>
        <input pInputText type="text" (input)="filter($event)" placeholder="Search" />
      </span>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th style="width: 3rem"></th>
      <th>{{ 'workflow.cubes.create-data-frame-column-mapping.table-header.column-name' | translate }}</th>
      <th>{{ 'workflow.cubes.create-data-frame-column-mapping.table-header.column-data-type' | translate }}</th>
      <th>{{ 'workflow.cubes.create-data-frame-column-mapping.table-header.actions' | translate }}</th>
      <th>{{ 'workflow.cubes.create-data-frame-column-mapping.table-header.used' | translate }}</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-item let-index="rowIndex">
    <tr [formGroup]="item" [pReorderableRow]="index">
      <td>
        <span class="pi pi-bars" pReorderableRowHandle></span>
      </td>
      <td>
        <input
          type="text"
          pInputText
          class="p-inputtext-sm w-full"
          formControlName="columnName"
          placeholder="{{
            'workflow.cubes.create-data-frame-column-mapping.field-placeholder.column-name' | translate
          }}"
        />
      </td>
      <td>
        <p-dropdown
          appendTo="body"
          [options]="dataTypeItems"
          formControlName="columnType"
          placeholder="{{
            'workflow.cubes.create-data-frame-column-mapping.field-placeholder.column-data-type' | translate
          }}"
        ></p-dropdown>
      </td>

      <td>
        <button
          type="button"
          pButton
          class="p-button-text p-button-link p-button-danger p-0"
          appearance="icon"
          (click)="removeItem(index)"
          icon="pi pi-trash"
          pTooltip="{{ 'workflow.cubes.create-data-frame-column-mapping.actions.remove' | translate }}"
        ></button>

        <button
          type="button"
          pButton
          class="p-button-text p-button-link p-0"
          appearance="icon"
          (click)="copyItem(index)"
          icon="pi pi-copy"
          pTooltip="{{ 'workflow.cubes.create-data-frame-column-mapping.actions.copy' | translate }}"
        ></button>
      </td>
      <td>
        <selfai-platform-used-in-panel
          [usedInNodes]="item.controls.columnName.value | selfaiPlatformUsedIn: nodeId | async"
        ></selfai-platform-used-in-panel>
      </td>
    </tr>
  </ng-template>

  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="4">{{ 'workflow.cubes.create-data-frame-column-mapping.no-data' | translate }}</td>
    </tr>
  </ng-template>
</p-table>

<button
  label="{{ 'workflow.cubes.create-data-frame-column-mapping.actions.add-column' | translate }}"
  pButton
  pRipple
  icon="pi pi-plus"
  type="button"
  class="mr-3 mt-3 p-button-sm"
  (click)="addItem()"
></button>
