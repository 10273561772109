@if({ isAppying: isAppying$ | async }; as obs) {
<selfai-platform-dialog-header
  [minimizeButtonShow]="false"
  [header]="'workflow.cubes.create-data-frame.modal-header' | translate"
  [nodeId]="nodeId"
  [saveButtonDisabled]="form.invalid"
  (clickSave)="onSubmit()"
  (closeDialog)="onCloseDialog()"
>
  <ng-container slot="button-pre">
    <button
      type="button"
      (click)="onClickApplyAndRun()"
      (keydown.enter)="onClickApplyAndRun()"
      tabindex="-1"
      pRipple
      pButton
      [icon]="obs.isAppying ? 'pi pi-spin pi-spinner' : 'pi pi-play-circle'"
      [disabled]="obs.isAppying"
      label="{{ 'workflow.cubes.create-data-frame.apply-and-run' | translate }}"
      pTooltip="{{
        dataSourceWasChanged ? ('workflow.cubes.create-data-frame.apply-and-run.warning' | translate) : ''
      }}"
      tooltipPosition="top"
    ></button>
  </ng-container>
</selfai-platform-dialog-header>
@if(hasParameters) {
<p-tabView>
  <p-tabPanel header="{{ 'workflow.cubes.create-data-frame.tab-title.create-data-frame' | translate }}">
    <ng-template pTemplate="content">
      <selfai-platform-create-data-frame></selfai-platform-create-data-frame>
    </ng-template>
  </p-tabPanel>
  <p-tabPanel [disabled]="form.controls.columnMapping.length === 0 || form.controls.columnMapping.invalid">
    <ng-template pTemplate="header">
      <div
        class="pointer-events-auto"
        [pTooltip]="'workflow.cubes.create-data-frame.create-data-tab-disabled-tooltip' | translate"
        [tooltipDisabled]="form.controls.columnMapping.length > 0 && form.controls.columnMapping.valid"
        tooltipPosition="top"
      >
        {{ 'workflow.cubes.create-data-frame.tab-title.create-data' | translate }}
      </div>
    </ng-template>

    <ng-template pTemplate="content">
      <selfai-platform-create-data
        [dataItems]="dataItems"
        (dataItemsChange)="onCreateDataChange($event)"
      ></selfai-platform-create-data>
    </ng-template>
  </p-tabPanel>
</p-tabView>
} @else {
<p-message text="{{ 'workflow.cubes.create-data-frame.no-parameters' | translate }}" severity="error"></p-message>
} }
