import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory, EntityDataService } from '@ngrx/data';
import { KE_WORKFLOW_SETTINGS_PRESETS_ENTITY_NAME } from '@selfai-platform/store';
import { WorkflowPresets } from '../../workflow-presets/interfaces/workflow-presets.interface';
import { WorkflowSettingsPresetsService } from './workflow-settings-presets.service';

@Injectable({ providedIn: 'root' })
export class WorkflowSettingsPresetsStore extends EntityCollectionServiceBase<WorkflowPresets> {
  constructor(
    public entityDataService: EntityDataService,
    public workflowPresetsService: WorkflowSettingsPresetsService,
    public serviceElementsFactory: EntityCollectionServiceElementsFactory,
  ) {
    entityDataService.registerService(KE_WORKFLOW_SETTINGS_PRESETS_ENTITY_NAME, workflowPresetsService);
    super(KE_WORKFLOW_SETTINGS_PRESETS_ENTITY_NAME, serviceElementsFactory);
  }
}
