import { Injectable } from '@angular/core';
import { UrlPageParamsService } from '@selfai-platform/shared';
import {
  DataListViewComponentService,
  DataListViewItem,
  KE_ROOT_ROUTE,
  KE_WORKFLOW_PATH,
  SelectedItemsService,
  TableColumn,
} from '@selfai-platform/shell';
import { Observable, map } from 'rxjs';
import { WorkflowListComponentService, WorkflowListItem } from './workflow-list-component.service';

@Injectable()
export class WorkflowDataListViewService extends DataListViewComponentService<WorkflowListItem> {
  constructor(
    private readonly workflowListComponentService: WorkflowListComponentService,
    urlPageParamsService: UrlPageParamsService,
    selectedItemsService: SelectedItemsService<WorkflowListItem>,
  ) {
    super(urlPageParamsService, selectedItemsService);
  }

  override loadData(): Observable<{ items: WorkflowListItem[]; totalItems: number }> {
    return this.workflowListComponentService
      .loadWorkflowList()
      .pipe(map((items) => ({ items, totalItems: items.length })));
  }

  override getData(): Observable<WorkflowListItem[]> {
    return this.workflowListComponentService
      .getWorkflowList()
      .pipe(
        map((items) =>
          items ? items.map((workflow) => this.addIcon(workflow)).map((workflow) => this.addRouterLink(workflow)) : [],
        ),
      );
  }

  override isLoading(): Observable<boolean> {
    return this.workflowListComponentService.loading$.asObservable();
  }

  override isLoaded(): Observable<boolean> {
    return this.workflowListComponentService.loaded$.asObservable();
  }

  override hasError(): Observable<boolean> {
    return this.workflowListComponentService.error$.asObservable();
  }

  getColumns(): TableColumn<WorkflowListItem>[] {
    return [
      {
        labelTranslate: 'workflow-list.table.columns.name',
        fieldCode: 'name',
        fieldType: 'text',
        classStyle: 'no-breaks short-text',
        width: 'calc(50% - 41rem)',
        resizable: true,
        sortable: true,
      },
      {
        labelTranslate: 'workflow-list.table.columns.description',
        fieldCode: 'description',
        fieldType: 'text',
        classStyle: 'no-breaks short-text',
        width: 'calc(50% - 43rem)',
        resizable: true,
        sortable: true,
      },
      {
        labelTranslate: 'workflow-list.table.columns.created',
        fieldCode: 'created',
        fieldType: 'date',
        classStyle: 'no-breaks short-text',
        width: '10rem',
        resizable: true,
        sortable: true,
      },
      {
        labelTranslate: 'workflow-list.table.columns.updated',
        fieldCode: 'updated',
        fieldType: 'date',
        classStyle: 'no-breaks short-text',
        width: '12rem',
        resizable: true,
        sortable: true,
      },
      {
        labelTranslate: 'workflow-list.table.columns.owner-name',
        fieldCode: 'ownerName',
        fieldType: 'text',
        classStyle: 'no-breaks short-text',
        width: '9rem',
        resizable: true,
        sortable: true,
      },
      {
        labelTranslate: 'workflow-list.table.columns.running',
        fieldCode: 'running',
        fieldType: 'boolean',
        classStyle: 'no-breaks',
        sortable: true,
        resizable: true,
        width: '9rem',
      },
    ];
  }

  private addIcon(workflow: WorkflowListItem): WorkflowListItem {
    return {
      ...workflow,
      icon: 'pi pi-fw pi-sitemap',
      iconClass: 'text-primary-500',
    };
  }

  private addRouterLink(workflow: WorkflowListItem): WorkflowListItem & Pick<DataListViewItem, 'routerLinkToItem'> {
    return {
      ...workflow,
      routerLinkToItem: {
        routerLink: ['/', KE_ROOT_ROUTE, KE_WORKFLOW_PATH, workflow.id],
      },
    };
  }
}
