import { AbstractControl, ValidationErrors } from '@angular/forms';

export function validJsonValidator(control: AbstractControl): ValidationErrors | null {
  // we don't validate empty values
  if (!control.value) {
    return null;
  }

  try {
    JSON.parse(control.value);
  } catch (e) {
    return { invalidJson: true };
  }

  return null;
}
