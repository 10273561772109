export const SCHEDULES_TABLE_COLUMNS_CONFIG = [
  {
    labelTranslate: 'schedules-list.table.columns.cron',
    fieldCode: 'name',
    fieldType: 'custom',
    classStyle: 'no-breaks short-text',
    width: '11rem',
    resizable: true,
    sortable: true,
  },
  {
    labelTranslate: 'schedules-list.table.columns.workflow-name',
    fieldCode: 'workflowName',
    fieldType: 'text',
    classStyle: 'no-breaks short-text',
    sortable: true,
    resizable: true,
    width: '12rem',
  },
  {
    labelTranslate: 'schedules-list.table.columns.timezone',
    fieldCode: 'timezone',
    fieldType: 'text',
    classStyle: 'no-breaks short-text',
    width: '12rem',
    resizable: true,
    sortable: true,
  },
  {
    labelTranslate: 'schedules-list.table.columns.run-clone',
    fieldCode: 'runClone',
    fieldType: 'boolean',
    classStyle: 'no-breaks short-text',
    sortable: true,
    resizable: true,
    width: '12rem',
  },
  {
    labelTranslate: 'schedules-list.table.columns.stop-session',
    fieldCode: 'stopSessionBeforeRun',
    fieldType: 'boolean',
    classStyle: 'no-breaks short-text',
    sortable: true,
    resizable: true,
    width: '12rem',
  },
  {
    labelTranslate: 'schedules-list.table.columns.preset',
    fieldCode: 'presetName',
    fieldType: 'text',
    classStyle: 'no-breaks short-text',
    sortable: true,
    resizable: true,
    width: '12rem',
  },
];

export const FILTER_FIELDS = ['workflowName', 'presetName', 'name'];
