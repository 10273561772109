import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import {
  DestroyService,
  LocalStorageKey,
  PREFIX_SETTINGS_LOCAL_STORAGE_KEY,
  PresetsPermissionAction,
} from '@selfai-platform/shared';
import {
  DataListViewComponent,
  GroupAction,
  provideDataListView,
  provideDialogService,
  ToolbarAction,
} from '@selfai-platform/shell';
import { combineLatest, map, Observable } from 'rxjs';
import { getPresetsPathCreatePath } from '../../functions';
import {
  WorkflowPresetsActionForItemService,
  WorkflowPresetsListViewService,
  WorkflowPresetsPermissionService,
} from '../../services';

@Component({
  selector: 'selfai-platform-workflow-presets-list',
  standalone: true,
  imports: [CommonModule, DataListViewComponent, TranslateModule],
  providers: [
    DestroyService,
    ...provideDataListView(WorkflowPresetsListViewService, WorkflowPresetsActionForItemService),
    provideDialogService(),
    {
      provide: PREFIX_SETTINGS_LOCAL_STORAGE_KEY,
      useValue: LocalStorageKey.DATA_SOURCE_LIST_PREFIX_SETTINGS,
    },
  ],
  templateUrl: './workflow-presets-list.component.html',
  styleUrl: './workflow-presets-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkflowPresetsListComponent {
  public columns = this.workflowPresetsListViewService.getColumns();
  public actions$: Observable<ToolbarAction[]> = this.getActions();
  public groupActions$: Observable<GroupAction[]> = this.workflowPresetsListViewService.getGroupActions();

  constructor(
    private readonly workflowPresetsListViewService: WorkflowPresetsListViewService,
    private readonly workflowPresetsPermissionService: WorkflowPresetsPermissionService,
    private readonly router: Router,
  ) {}

  private getActions(): Observable<ToolbarAction[]> {
    return combineLatest({
      createPermission: this.workflowPresetsPermissionService.checkPermission(PresetsPermissionAction.Create),
    }).pipe(
      map(({ createPermission }) => {
        const actions = [];
        if (createPermission) {
          actions.push({
            tooltipTranslate: 'workflow.preset.tooltip.actions.create',
            icon: 'pi pi-plus-circle',
            buttonClass: 'p-button-success',
            action: () => {
              this.router.navigate(getPresetsPathCreatePath()).then();
            },
          });
        }
        return actions as ToolbarAction[];
      }),
    );
  }
}
