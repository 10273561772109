// todo move to ./facades and rename WorkflowPresetsDomainService -> WorkflowPresetsFacade
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { WorkflowPresets } from '../interfaces/workflow-presets.interface';
import { WorkflowPresetsStore } from '../store/workflow-presets.store';

@Injectable({
  providedIn: 'root',
})
export class WorkflowPresetsDomainService {
  workflowPresets$!: Observable<WorkflowPresets[]>;
  loading$!: Observable<boolean>;
  loaded$!: Observable<boolean>;
  errors$!: Observable<boolean>;

  constructor(private workflowPresetsStore: WorkflowPresetsStore) {
    this.workflowPresets$ = workflowPresetsStore.entities$;
    this.loading$ = workflowPresetsStore.loading$;
    this.loaded$ = workflowPresetsStore.loaded$;
  }

  presetsEntities$(): Observable<WorkflowPresets[]> {
    return this.workflowPresetsStore.entities$;
  }

  getListObservable(): Observable<{ presets: WorkflowPresets[] }> {
    return this.workflowPresetsStore.getAll().pipe(map((presets) => ({ presets })));
  }

  getList(): void {
    this.workflowPresetsStore.getAll();
  }

  getItem(id: string): void {
    this.workflowPresetsStore.getByKey(id);
  }

  add(data: WorkflowPresets): void {
    this.workflowPresetsStore.add(data);
  }

  update(data: WorkflowPresets): void {
    this.workflowPresetsStore.update(data);
  }

  delete(id: string): void {
    this.workflowPresetsStore.delete(id);
  }
}
