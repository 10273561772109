import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { DataSource } from '../model/data-source.model';

export function uniqueNameValidator(dataSourceList: DataSource[], currentId?: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const name = control.value.trim();

    // we don't need to validate if the name was not changed
    if (!name || control.pristine) {
      return null;
    }

    const isUnique = !dataSourceList.some((item) => item.params?.name === name && item.id !== currentId);

    return isUnique ? null : { notUniq: true };
  };
}
