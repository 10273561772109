import { HttpClient } from '@angular/common/http';
import { AlertService, PipelineConfigService } from '@selfai-platform/shared';
import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { map, Observable, tap } from 'rxjs';
import { WorkflowSettingsPresetsBinding } from '../interface/workflow-settings-presets-binding.interface';
import { KE_WORKFLOW_SETTINGS_PRESETS_BINDING_ENTITY_NAME } from '@selfai-platform/store';

@Injectable({
  providedIn: 'root',
})
export class WorkflowSettingsPresetsBindingService extends DefaultDataService<WorkflowSettingsPresetsBinding> {
  config = this.pipelineConfigService.getConfig();
  apiUrl = `${this.config.hosts.api}/${this.config.versions.url}`;

  constructor(
    public override http: HttpClient,
    public override httpUrlGenerator: HttpUrlGenerator,
    private pipelineConfigService: PipelineConfigService,
    private alertService: AlertService,
  ) {
    super(KE_WORKFLOW_SETTINGS_PRESETS_BINDING_ENTITY_NAME, http, httpUrlGenerator);
  }

  override add(data: WorkflowSettingsPresetsBinding): Observable<WorkflowSettingsPresetsBinding> {
    return this.http
      .post(`${this.apiUrl}/workflows/${data.id}/preset`, data, {
        headers: {
          Accept: 'text/plain',
        },
        responseType: 'text',
      })
      .pipe(
        map((n) => data),
        tap({
          next: () => {
            this.alertService.success(`Workflow preset changed`);
          },
          error: () => {
            this.alertService.error(`Editing error`);
          },
        }),
      );
  }
}
