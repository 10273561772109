import { EntityMetadataMap } from '@ngrx/data';

export const KE_SCHEDULES_LIST_ENTITY_NAME = 'keSchedules';

const entityMetadata: EntityMetadataMap = {
  [KE_SCHEDULES_LIST_ENTITY_NAME]: {
    entityDispatcherOptions: { optimisticAdd: false, optimisticUpdate: false },
  },
};

export const keSchedulesEntityConfig = {
  entityMetadata,
};
