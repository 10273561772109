<form class="pt-4" [formGroup]="form">
  <ng-container>
    <div class="p-float-label mb-5">
      <input class="w-full" pInputText [formControl]="paramsForm.controls.driver" />
      <label>Driver</label>
    </div>

    <div class="p-float-label mb-5">
      <p-password
        styleClass="w-full"
        inputStyleClass="w-full"
        [formControl]="paramsForm.controls.url"
        [toggleMask]="true"
        [feedback]="false"
      ></p-password>
      <label>JDBC connection string</label>
    </div>

    <div class="flex gap-3 mb-5">
      <div class="flex align-items-center" *ngFor="let category of categories">
        <p-radioButton
          [inputId]="category.id"
          [value]="category.id"
          [label]="category.label"
          [formControl]="paramsForm.controls.selectedCategory"
          (click)="categoryLabel = category.label; toggleCategoryControl(category.id)"
        ></p-radioButton>
      </div>
    </div>

    @if (paramsForm.controls.selectedCategory.value === dataSourcesCategoriesEnum.TABLE) {
      <div *ngIf="paramsForm.get('table')" class="p-float-label mb-5">
        <input class="w-full" pInputText [formControl]="paramsForm.controls.table" />
        <label>Table name</label>
      </div>
    } @else {
      <selfai-platform-code-editor
        *ngIf="paramsForm.get('query')"
        class="p-inputtext mb-5"
        language="sparksql"
        [formControl]="paramsForm.controls.query"
      ></selfai-platform-code-editor>
    }
  </ng-container>

  <div class="mb-5">
    <selfai-platform-data-source-visibility
      [control]="form.controls.visibility"
    ></selfai-platform-data-source-visibility>
  </div>
  <div class="mb-5">
    <selfai-platform-data-source-unique-name [control]="form.controls.name"></selfai-platform-data-source-unique-name>
  </div>

  <selfai-platform-data-sources-additionals
    [properties]="dataSource?.params?.properties"
    [additionalList]="additionalList"
    (changeEvent)="onPropertiesValue($event)"
  ></selfai-platform-data-sources-additionals>
</form>
