import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CubeDndPlaceholderComponent } from './cube-dnd-placeholder.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [CommonModule, TranslateModule],
  declarations: [CubeDndPlaceholderComponent],
  exports: [CubeDndPlaceholderComponent],
})
export class CubeDndPlaceholderComponentModule {}
