import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DatasourceType, IAngularJsPipelineOptions, InitLegacyPipelineParams } from '@selfai-platform/pipeline-common';
import {
  DestroyService,
  PipelineConfigService,
  ScriptNames,
  ScriptService,
  SELFAI_APP_BASE_HREF,
  SelfaiAuthService,
} from '@selfai-platform/shared';
import { EditorStateService, WorkflowStateService } from '@selfai-platform/storage';
import * as Ng1 from 'angular';
import { combineLatestWith, from, map, startWith, switchMap, takeUntil, tap } from 'rxjs';
import { KE_AVALIABLE_DATASOURCES } from '../../common';
import { KeMqInfoService } from '../../mq/services/ke-mq-info.service';
import { LegacyDatasourcePermissionService, WorkflowPermissionService } from '../../wokflow-list';
import { AngularJsBridgeService } from './AngularJsBridgeService';

declare global {
  interface Window {
    initLegacyPipeline: (params: InitLegacyPipelineParams) => Promise<ng.auto.IInjectorService>;
  }
}

@Injectable({ providedIn: 'root' })
export class AngularJsLoaderService {
  readonly defualtSelectorForBootstrap = '#ppl-ng-app';

  app: Ng1.auto.IInjectorService | undefined;

  constructor(
    private readonly scriptService: ScriptService,
    private readonly destroy$: DestroyService,
    private readonly angularBridge: AngularJsBridgeService,
    private readonly pipelineConfigService: PipelineConfigService,
    private readonly selfaiAuthService: SelfaiAuthService,
    private readonly workflowStateService: WorkflowStateService,
    private readonly workflowPermissionService: WorkflowPermissionService,
    private readonly editoreStateService: EditorStateService,
    private readonly datasourcePermissionService: LegacyDatasourcePermissionService,
    private readonly mqInfoService: KeMqInfoService,
    private readonly translateService: TranslateService,
    @Inject(SELFAI_APP_BASE_HREF) private readonly baseHref: string,
    @Inject(KE_AVALIABLE_DATASOURCES) private readonly avaliableDatasources: DatasourceType[],
  ) {}

  public bootstrapPipeline(options: IAngularJsPipelineOptions, onBootstrapEnd?: { (): void }): void {
    const optionsWithSelector: IAngularJsPipelineOptions = {
      ...options,
      baseHref: this.baseHref,
      rootSelector: options.rootSelector || this.defualtSelectorForBootstrap,
    };
    from(this.scriptService.loadScript(ScriptNames.PplLegacyVendor))
      .pipe(
        switchMap(() => from(this.scriptService.loadScript(ScriptNames.PplLegacyUtils))),
        switchMap(() => from(this.scriptService.loadScript(ScriptNames.PplLegacyApp))),
        switchMap(() => from(this.mqInfoService.getMqInfo())),
        combineLatestWith(this.workflowPermissionService.getWorkflowPermissions(options.workflowId as string)),
        switchMap((args) =>
          this.translateService.onLangChange.pipe(
            startWith(null),
            map(() => args),
          ),
        ),
        tap(() => this.resetRootElement()),
        switchMap(([mqInfo, workflowPermissions]) =>
          from(
            window.initLegacyPipeline({
              options: optionsWithSelector,
              config: this.pipelineConfigService.getConfig(),
              workflowPermissions,
              mqInfo,
              services: {
                selfaiAuthService: this.selfaiAuthService.getProvider(),
                workflowStateService: this.workflowStateService,
                editoreStateService: this.editoreStateService,
                datasourcePermissionService: this.datasourcePermissionService,
                avaliableDatasources: this.avaliableDatasources,
                translateService: this.translateService,
              },
            }),
          ),
        ),
        takeUntil(this.destroy$),
      )
      .subscribe((app) => {
        this.app = app;
        this.angularBridge.setHookService(this.app.get('AngularBridgeService'));

        if (onBootstrapEnd) {
          onBootstrapEnd();
        }
      });
  }

  public destroyPipeline(): void {
    if (this.app) {
      this.angularBridge.clearHookService();
      this.app.get('$rootScope').$destroy();
    }
  }

  private resetRootElement(): void {
    if (!this.app) {
      return;
    }

    this.destroyPipeline();
    const oldElement = this.app.get('$rootElement');
    const newElement = oldElement.clone(false);
    oldElement.replaceWith(newElement);
  }
}
