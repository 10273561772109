import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DestroyService, PresetsPermissionAction } from '@selfai-platform/shared';
import { BreadcrumbsMenuItem, BreadcrumbsMenuService } from '@selfai-platform/shell';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { FloatLabelModule } from 'primeng/floatlabel';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ToastModule } from 'primeng/toast';
import { ToolbarModule } from 'primeng/toolbar';
import { combineLatest, take, takeUntil } from 'rxjs';
import { getPresetsPath, getPresetsPathCreatePath, getPresetsPathEditPath } from '../../functions';
import { WorkflowPresets } from '../../interfaces';
import { WorkflowPresetsPermissionService } from '../../services';

@Component({
  selector: 'selfai-platform-workflow-presets',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    InputTextModule,
    InputTextareaModule,
    InputNumberModule,
    DropdownModule,
    ToastModule,
    CardModule,
    ToolbarModule,
    ButtonModule,
    FloatLabelModule,
    CheckboxModule,
  ],
  templateUrl: './workflow-presets.component.html',
  styleUrl: './workflow-presets.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class WorkflowPresetsComponent implements OnChanges, OnInit {
  public canUpdate: boolean = false;
  public clusterType = [
    { name: 'Standalone', value: 'standalone' },
    { name: 'Local', value: 'local' },
  ];
  public id: string = this.activatedRoute?.snapshot?.params['id'];
  public editMode = !!this.id;
  public breadcrumbItems: BreadcrumbsMenuItem[] = [
    {
      label: this.translate.instant('shell.menu.presets'),
      routerLink: getPresetsPath(),
    },
  ];
  public currentWorkflowPreset!: WorkflowPresets;
  public form: FormGroup = this.fb.group({
    id: [''],
    params: [''],
    name: ['', [Validators.required]],
    userIP: [''],
    clusterType: [null, [Validators.required]],
    uri: ['', [Validators.required]],
    isDefault: [false],
    isEditable: [true],
    driverMemory: ['', [Validators.required]],
    executorMemory: ['', [Validators.required]],
    executorCores: [1, [Validators.required]],
    numExecutors: [1, [Validators.required]],
    totalExecutorCores: [{ value: 1 }, Validators.required],
    hadoopUser: [''],
  });

  @Input() workflowPresets: WorkflowPresets[];

  @Output() backEvent = new EventEmitter<void>();
  @Output() submitEvent = new EventEmitter<WorkflowPresets>();

  constructor(
    private readonly fb: FormBuilder,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly activatedRoute: ActivatedRoute,
    private readonly translate: TranslateService,
    private readonly breadcrumbsMenuService: BreadcrumbsMenuService,
    private readonly destroy$: DestroyService,
    private readonly permissionService: WorkflowPresetsPermissionService,
    private readonly router: Router,
  ) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['workflowPresets']?.currentValue?.length) {
      if (this.editMode) {
        combineLatest([
          this.permissionService.checkPermission(PresetsPermissionAction.Get),
          this.permissionService.checkPermission(PresetsPermissionAction.Update),
        ])
          .pipe(take(1))
          .subscribe(([canView, canUpdate]) => {
            if (!canView) {
              this.router.navigate(['access-denied']).then();
            }
            this.canUpdate = canUpdate;
            if (!canUpdate) {
              this.form.disable();
            }
          });
        this.currentWorkflowPreset = this.workflowPresets.find((wp) => {
          return wp.id === this.id;
        });
        if (this.breadcrumbItems.length < 2) {
          this.breadcrumbItems?.push({
            label: `${this.translate.instant('shell.bc.presets.label.edit')} ${this.currentWorkflowPreset?.name}`,
            routerLink: getPresetsPathEditPath(this.currentWorkflowPreset?.id.toString() || ''),
          });
        }
      } else {
        this.currentWorkflowPreset = changes['workflowPresets']?.currentValue[0];
        this.breadcrumbItems?.push({
          label: `${this.translate.instant('shell.bc.presets.label.create')}`,
          routerLink: getPresetsPathCreatePath(),
        });
      }
      this.breadcrumbsMenuService.setBreadcrumbsMenu(this.breadcrumbItems);
      this.form.setValue(this.currentWorkflowPreset);
      this.changeDetectorRef.detectChanges();
    }
  }

  public ngOnInit(): void {
    this.form
      .get('executorCores')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.setTotalExecutorCores();
      });

    this.form
      .get('numExecutors')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.setTotalExecutorCores();
      });
  }

  public setTotalExecutorCores(): void {
    this.form
      .get('totalExecutorCores')
      .setValue((this.form.get('executorCores').value || 1) * (this.form.get('numExecutors').value || 1));
  }

  public onBackEvent(): void {
    this.backEvent.emit();
  }

  public onSubmit(): void {
    this.form.markAllAsTouched();
    this.changeDetectorRef.detectChanges();
    if (this.form.valid) {
      const formValue = this.form.getRawValue();
      this.submitEvent.emit(formValue);
    }
  }
}
