import { EntityMetadataMap } from '@ngrx/data';

export const KE_SCHEDULES_PRESETS_ENTITY_NAME = 'keSchedulesPresets';

const entityMetadata: EntityMetadataMap = {
  [KE_SCHEDULES_PRESETS_ENTITY_NAME]: {
    entityDispatcherOptions: { optimisticAdd: false, optimisticUpdate: false },
  },
};

export const keSchedulesPresetsEntityConfig = {
  entityMetadata,
};
