<div class="field">
  <label for="data-source">{{ 'workflow.cubes.create-data-frame.field-label.data-source' | translate }}</label>

  <selfai-platform-data-source-selector
    [control]="form.controls.dataSourceId"
    [excludedDataSourceTypes]="['kafka']"
    class="h-15rem"
  ></selfai-platform-data-source-selector>
</div>
<selfai-platform-create-data-frame-column-maping></selfai-platform-create-data-frame-column-maping>
