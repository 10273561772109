import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PresetsPermissionAction, UrlPageParamsService } from '@selfai-platform/shared';
import {
  convertMessageToHtml,
  DataListViewComponentService,
  GroupAction,
  SelectedItemsService,
  TableColumn,
} from '@selfai-platform/shell';
import { ConfirmationService } from 'primeng/api';
import { combineLatest, map, Observable, take } from 'rxjs';
import { getPresetsPathEditPath } from '../functions';
import { WorkflowPresets } from '../interfaces';
import { WorkflowPresetsDomainService } from './workflow-presets-domain.service';
import { WorkflowPresetsPermissionService } from './workflow-presets-permission.service';

@Injectable({
  providedIn: 'root',
})
export class WorkflowPresetsListViewService extends DataListViewComponentService<WorkflowPresets> {
  constructor(
    private readonly workflowPresetsDomainService: WorkflowPresetsDomainService,
    private readonly confirmationService: ConfirmationService,
    private readonly translate: TranslateService,
    private readonly workflowPresetsPermissionService: WorkflowPresetsPermissionService,
    urlPageParamsService: UrlPageParamsService,
    selectedItemsService: SelectedItemsService<any>,
  ) {
    super(urlPageParamsService, selectedItemsService);
  }

  override loadData(): Observable<{ items: WorkflowPresets[]; totalItems: number }> {
    return this.workflowPresetsDomainService.getListObservable().pipe(
      map(({ presets }) => ({
        items: presets.map((item) => this.normalizeForView(item)),
        totalItems: presets.length,
      })),
    );
  }

  private normalizeForView(preset: WorkflowPresets, permissions?: Record<string, boolean>): any {
    let normalized = {
      ...preset,
      icon: 'pi pi-microchip',
      iconClass: 'text-bluegrey-500',
    };

    if (permissions?.['presetsPermissionsGet']) {
      normalized = {
        ...normalized,
        routerLinkToItem: {
          routerLink: getPresetsPathEditPath(preset.id?.toString() || ''),
        },
      } as { icon: string; iconClass: string } & WorkflowPresets;
    }
    return normalized;
  }

  override getData(): Observable<WorkflowPresets[]> {
    return combineLatest({
      presets: this.workflowPresetsDomainService.workflowPresets$ as Observable<WorkflowPresets[]>,
      presetsPermissionsGet: this.workflowPresetsPermissionService.checkPermission(PresetsPermissionAction.Get),
      presetsPermissionsUpdate: this.workflowPresetsPermissionService.checkPermission(PresetsPermissionAction.Update),
    }).pipe(
      map(({ presets, ...permissions }) =>
        presets.map((preset: WorkflowPresets) => this.normalizeForView(preset, { ...permissions })),
      ),
    );
  }

  override isLoading(): Observable<boolean> {
    return this.workflowPresetsDomainService.loading$;
  }

  override isLoaded(): Observable<boolean> {
    return this.workflowPresetsDomainService.loaded$;
  }

  override hasError(): Observable<boolean> {
    return this.workflowPresetsDomainService.errors$.pipe(map((error) => !!error));
  }

  getColumns(): TableColumn<any>[] {
    return [
      {
        labelTranslate: 'presets-list.table.columns.name',
        fieldCode: 'name',
        fieldType: 'text',
        classStyle: 'no-breaks short-text',
        width: 'calc(50% - 41rem)',
        resizable: true,
        sortable: true,
      },
      {
        labelTranslate: 'presets-list.table.columns.driver-memory',
        fieldCode: 'driverMemory',
        fieldType: 'text',
        width: '12rem',
        resizable: true,
        sortable: true,
      },
      {
        labelTranslate: 'presets-list.table.columns.executor-memory',
        fieldCode: 'executorMemory',
        fieldType: 'text',
        width: '10rem',
        resizable: true,
        sortable: true,
      },
      {
        labelTranslate: 'presets-list.table.columns.executor-cores',
        fieldCode: 'executorCores',
        fieldType: 'number',
        width: '12rem',
        resizable: true,
        sortable: true,
      },
      {
        labelTranslate: 'presets-list.table.columns.num-executors',
        fieldCode: 'numExecutors',
        fieldType: 'number',
        width: '15rem',
        resizable: true,
        sortable: true,
      },
      {
        labelTranslate: 'presets-list.table.columns.isDefault',
        fieldCode: 'isDefault',
        fieldType: 'boolean',
        width: '10rem',
        resizable: true,
        sortable: true,
      },
    ];
  }

  getGroupActions(): Observable<GroupAction[]> {
    return this.workflowPresetsPermissionService.checkPermission(PresetsPermissionAction.Delete).pipe(
      map((permission) => {
        if (permission) {
          return [
            {
              tooltipTranslate: 'workflow-list.toolbar.actions.delete-selected',
              icon: 'pi pi-trash',
              buttonClass: 'p-button-danger',
              action: () => this.deleteSelected(),
            },
          ];
        } else {
          return [];
        }
      }),
    );
  }

  private deleteSelected(): void {
    // TODO: Move to the confirmation with key = dialog to data list view
    this.confirmationService.confirm({
      key: 'dialog',
      message: convertMessageToHtml(
        this.translate.instant('workflow.preset.toolbar.actions.delete-selected.confirmation'),
      ),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.selectedItemsService
          .getSelectedItemIds()
          .pipe(take(1))
          .subscribe((ids) => {
            ids.forEach((id) => {
              this.workflowPresetsDomainService.delete(id);
            });
            this.selectedItemsService.resetSelectedItems();
          });
      },
    });
  }
}
