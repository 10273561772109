import { Injectable } from '@angular/core';
import { NodeUsingColumn } from '@selfai-platform/pipeline-common';
import { WorkflowStateService } from '@selfai-platform/storage';
import { map, Observable } from 'rxjs';
import { collectAllValues } from '../../../utils';

@Injectable({ providedIn: 'root' })
export class ColumnUsedInWorkflowService {
  constructor(private readonly workflowStateService: WorkflowStateService) {}

  getNodesUsingColumn(columnName: string, excludeNodeId?: string): Observable<NodeUsingColumn[]> {
    return this.workflowStateService.getWorkflowState().pipe(
      map((workflow) => {
        const nodesForSearch = workflow.workflow.nodes
          .filter((node) => node.id !== excludeNodeId)
          .map((node) => {
            const result = collectAllValues(node.parameters);
            const nodeId = node.id;
            return {
              nodeId,
              name: workflow.thirdPartyData.gui.nodes[nodeId]?.uiName || node.operation?.name || '',
              searchResult: result,
            };
          });

        return nodesForSearch
          .filter(({ searchResult }) => {
            return searchResult.some((searchText) => searchText.includes(columnName));
          })
          .map(({ nodeId, name }) => ({ nodeId, title: name }));
      }),
    );
  }
}
