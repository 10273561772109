<selfai-platform-data-list-view
  [layoutTypes]="['table']"
  [actions]="actions$ | async"
  [groupActions]="groupActions$ | async"
  [columns]="columns"
  [showEntityIcon]="false"
  [emptyMessage]="'shell.board.filter.ui.search-all.nodata' | translate"
  [globalFilterFields]="['name']"
>
  <ng-template selfaiPlatformDataListViewTemplateName="fieldValue.isActive" let-item>
    <p-inputSwitch [ngModel]="item.isActive" (ngModelChange)="toggleActiveRoute(item.uuid)"></p-inputSwitch>
  </ng-template>
</selfai-platform-data-list-view>

<p-confirmPopup></p-confirmPopup>
