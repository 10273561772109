import { Component, EventEmitter, Input, OnChanges, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';

import {
  TIME_RANGE_FILTER_EARLIEST_DATETIME,
  TIME_RANGE_FILTER_LATEST_DATETIME,
  TimeUnit,
} from '@selfai-platform/bi-domain';
import { DestroyService } from '@selfai-platform/shared';

export class TimeRange {
  startDate: Date | string;
  endDate: Date | string;

  constructor(startDate: Date | string, endDate: Date | string) {
    this.startDate = typeof startDate === 'string' ? startDate.replace(/\.\d{3}Z/, '') : startDate;
    this.endDate = typeof endDate === 'string' ? endDate.replace(/\.\d{3}Z/, '') : endDate;
  }

  public toInterval() {
    return this.startDate + '/' + this.endDate;
  }
}

export class TimeRangeData {
  public minTime: Date;
  public maxTime: Date;
  public interval: TimeRange;
  public mockup: boolean;
  public timeUnit: TimeUnit;

  constructor(minTime: Date, maxTime: Date, interval: TimeRange, mockUp: boolean = false, timeUnit?: TimeUnit) {
    this.minTime = minTime;
    this.maxTime = maxTime;
    this.interval = interval;
    this.mockup = mockUp;
    this.timeUnit = timeUnit === undefined || timeUnit === null ? TimeUnit.NONE : timeUnit;
  }
}

class ComboItem {
  public label: string;
  public value: number;

  constructor(label: string, value: number) {
    this.label = label;
    this.value = value;
  }
}

export enum ETimeRangeMode {
  CHANGE = 'CHANGE',
  WIDGET = 'WIDGET',
}

@Component({
  selector: 'selfai-bi-time-range',
  templateUrl: './time-range.component.html',
  styleUrls: ['./time-range.component.scss'],
  providers: [DestroyService],
})
export class TimeRangeComponent implements OnChanges {
  private quarterList: ComboItem[] = [];
  private weekList: ComboItem[] = [];

  @Input() mode: ETimeRangeMode = ETimeRangeMode.CHANGE;
  @Input() initialDate: TimeRangeData;

  @Output() dateChange: EventEmitter<TimeRange> = new EventEmitter();

  public form = new FormGroup({
    from: new FormControl(null),
    to: new FormControl(null),
  });

  public comboList: ComboItem[] = [];
  public fromComboIdx = 0;
  public toComboIdx = 0;
  public selectedFromComboItem: ComboItem;
  public selectedToComboItem: ComboItem;
  public isEarliestDateTime = false;
  public isLatestDateTime = false;

  constructor(private readonly destroy$: DestroyService) {
    for (let idx = 1; idx <= 52; idx++) {
      this.weekList.push(new ComboItem('W' + idx, idx));
    }

    for (let idx = 1; idx <= 4; idx++) {
      this.quarterList.push(new ComboItem('Q' + idx, idx));
    }
  }

  public ngOnChanges(changes: SimpleChanges) {
    const compDataChanges: SimpleChange = changes.initialDate;
    if (compDataChanges) {
      const currVal: TimeRangeData = compDataChanges.currentValue;
      const prevVal: TimeRangeData = compDataChanges.previousValue;
      if (
        !this.initialDate.mockup &&
        (!prevVal ||
          currVal.minTime !== prevVal.minTime ||
          currVal.maxTime !== prevVal.maxTime ||
          currVal.interval !== prevVal.interval ||
          currVal.timeUnit !== prevVal.timeUnit)
      ) {
        this.setPicker();
      }
    }
  }

  get isWidget(): boolean {
    return this.mode === ETimeRangeMode.WIDGET;
  }

  get isComboList(): boolean {
    return this.comboList.length > 0;
  }

  public onClose(isStart: boolean) {
    const timeRange = this.getTimeRange(isStart);
    this.dateChange.emit(timeRange);
  }

  protected onSelectComboItem(item: ComboItem, isFrom: boolean) {
    if (isFrom) {
      this.selectedFromComboItem = item;
    } else {
      this.selectedToComboItem = item;
    }
    this.dateChange.emit(this.getTimeRange(false));
  }

  private setPicker() {
    const { minTime, maxTime, interval } = this.initialDate;

    this.isEarliestDateTime = interval.startDate === TIME_RANGE_FILTER_EARLIEST_DATETIME;
    this.isLatestDateTime = interval.endDate === TIME_RANGE_FILTER_LATEST_DATETIME;

    const startDate = this.isEarliestDateTime ? minTime.toString() : interval.startDate.toString();
    const endDate = this.isLatestDateTime ? maxTime.toString() : interval.endDate.toString();

    let fromMoment;
    let arrDateInfo;

    if (startDate !== undefined) {
      fromMoment = this.customMoment(startDate);
    } else {
      fromMoment = moment.utc(minTime);
    }

    switch (this.initialDate.timeUnit) {
      case TimeUnit.WEEK:
        this.comboList = cloneDeep(this.weekList);
        arrDateInfo = startDate.split('-');
        fromMoment = moment(arrDateInfo[0] + '-01-01');
        this.fromComboIdx = this.comboList.findIndex((item) => item.value === Number(arrDateInfo[1]));
        this.selectedFromComboItem = this.comboList[this.fromComboIdx];
        break;
      case TimeUnit.QUARTER:
        this.comboList = cloneDeep(this.quarterList);
        this.fromComboIdx = this.comboList.findIndex((item) => item.value === fromMoment.quarter());
        this.selectedFromComboItem = this.comboList[this.fromComboIdx];
        break;
    }

    const toDate = fromMoment.toDate();
    this.form.controls['from'].setValue(toDate);

    let toMoment;

    if (endDate !== undefined) {
      toMoment = this.customMoment(endDate);
    } else {
      toMoment = moment.utc(maxTime);
    }
    switch (this.initialDate.timeUnit) {
      case TimeUnit.WEEK: {
        this.comboList = cloneDeep(this.weekList);
        const arrDateInfo = endDate.split('-');
        toMoment = moment(arrDateInfo[0] + '-01-01');
        const endWeek = Number(arrDateInfo[1]);
        this.toComboIdx = this.comboList.findIndex((item) => item.value === endWeek);
        this.selectedToComboItem = this.comboList[this.toComboIdx];
        break;
      }
      case TimeUnit.QUARTER: {
        this.comboList = cloneDeep(this.quarterList);
        const endQuarter: number = toMoment.quarter();
        this.toComboIdx = this.comboList.findIndex((item) => item.value === endQuarter);
        this.selectedToComboItem = this.comboList[this.toComboIdx];
        break;
      }
    }

    this.form.controls['to'].setValue(toMoment.toDate());
  }

  private customMoment(date: Date | string) {
    if (date.constructor === String) {
      return moment((<string>date).replace(/\.\d{3}Z/, ''));
    } else {
      return moment(date);
    }
  }

  private getTimeRange(isStart: boolean): TimeRange {
    this.checkDateRange(isStart);

    let { from, to } = this.form.getRawValue();

    from = `${moment(from).format('YYYY-MM-DD')}T${moment(from).format('HH:mm:ss.sss')}`;
    to = `${moment(to).format('YYYY-MM-DD')}T${moment(to).format('HH:mm:ss.sss')}`;

    const currTimeUnit: TimeUnit = this.initialDate.timeUnit;

    let fromDate;
    let toDate;

    switch (currTimeUnit) {
      case TimeUnit.YEAR:
        return this.getRangeFromMoment(
          this.isEarliestDateTime ? TIME_RANGE_FILTER_EARLIEST_DATETIME : moment().year(from.getFullYear()),
          this.isLatestDateTime ? TIME_RANGE_FILTER_LATEST_DATETIME : moment().year(to.getFullYear()),
          'year',
        );

      case TimeUnit.QUARTER:
        return this.getRangeFromMoment(
          this.isEarliestDateTime
            ? TIME_RANGE_FILTER_EARLIEST_DATETIME
            : moment().year(from.getFullYear()).quarter(this.selectedFromComboItem.value),
          this.isLatestDateTime
            ? TIME_RANGE_FILTER_LATEST_DATETIME
            : moment().year(to.getFullYear()).quarter(this.selectedToComboItem.value),
          'quarter',
        );

      case TimeUnit.MONTH:
        return this.getRangeFromMoment(
          this.isEarliestDateTime
            ? TIME_RANGE_FILTER_EARLIEST_DATETIME
            : moment().year(from.getFullYear()).month(from.getMonth()),
          this.isLatestDateTime
            ? TIME_RANGE_FILTER_LATEST_DATETIME
            : moment().year(to.getFullYear()).month(to.getMonth()),
          'month',
        );

      case TimeUnit.WEEK:
        return new TimeRange(
          this.isEarliestDateTime
            ? TIME_RANGE_FILTER_EARLIEST_DATETIME
            : to.getFullYear() + '-' + this.selectedFromComboItem.value,
          this.isLatestDateTime
            ? TIME_RANGE_FILTER_LATEST_DATETIME
            : from.getFullYear() + '-' + this.selectedToComboItem.value,
        );

      case TimeUnit.DAY:
        return new TimeRange(
          this.isEarliestDateTime
            ? TIME_RANGE_FILTER_EARLIEST_DATETIME
            : moment()
                .year(from.getFullYear())
                .month(from.getMonth())
                .date(from.getDate())
                .startOf('date')
                .format('YYYY-MM-DDTHH:mm:ss.sss') + 'Z',
          this.isLatestDateTime
            ? TIME_RANGE_FILTER_LATEST_DATETIME
            : moment()
                .year(to.getFullYear())
                .month(to.getMonth())
                .date(to.getDate())
                .startOf('date')
                .format('YYYY-MM-DDTHH:mm:ss.sss') + 'Z',
        );

      default:
        fromDate = this.isEarliestDateTime ? TIME_RANGE_FILTER_EARLIEST_DATETIME : moment.utc(from).toISOString();
        toDate = this.isLatestDateTime ? TIME_RANGE_FILTER_LATEST_DATETIME : moment.utc(to).toISOString();

        return new TimeRange(fromDate, toDate);
    }
  }

  /**
   * Check if start date is less than end date
   * */
  private checkDateRange(isStart) {
    const { from, to } = this.form.getRawValue();
    if (from && to && from.getTime() - to.getTime() > 0) {
      isStart ? this.form.controls['to'].setValue(from) : this.form.controls['from'].setValue(to);
    }
  }

  private getRangeFromMoment(fromMoment: any, toMoment: any, range: string): TimeRange {
    return new TimeRange(
      fromMoment.startOf(range).format('YYYY-MM-DDTHH:mm:ss.sss') + 'Z',
      toMoment.endOf(range).format('YYYY-MM-DDTHH:mm:ss.sss') + 'Z',
    );
  }
}
