import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { SchedulesList, SchedulesListItem } from '../models';
import { SchedulesListStore } from '../store';

@Injectable({
  providedIn: 'root',
})
export class SchedulesListDomainService {
  constructor(private readonly schedulesListStore: SchedulesListStore) {}

  public getSchedulesList(): Observable<SchedulesListItem[]> {
    return this.schedulesListStore.entities$ as Observable<SchedulesListItem[]>;
  }

  public loadSchedulesList(): Observable<SchedulesList> {
    return this.schedulesListStore.getAll().pipe(map((schedules) => ({ schedules })));
  }

  public getSchedulesListLoading(): Observable<boolean> {
    return this.schedulesListStore.loading$;
  }

  public getSchedulesListError(): Observable<unknown> {
    return this.schedulesListStore.errors$;
  }

  public getSchedulesListLoaded(): Observable<boolean> {
    return this.schedulesListStore.loaded$;
  }

  public deleteSchedule(id: string): void {
    this.schedulesListStore.delete(id);
  }
}
