import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory, EntityDataService } from '@ngrx/data';
import { KE_SCHEDULES_WORKFLOWS_LIST_ENTITY_NAME } from '@selfai-platform/store';
import { WorkflowInfo } from '@selfai-platform/pipeline-common';
import { SchedulesWorkflowsListDataService } from './workflows-list-data.service';

@Injectable({ providedIn: 'root' })
export class SchedulesWorkflowsListStore extends EntityCollectionServiceBase<WorkflowInfo> {
  constructor(
    public entityDataService: EntityDataService,
    public workflowsListService: SchedulesWorkflowsListDataService,
    public serviceElementsFactory: EntityCollectionServiceElementsFactory,
  ) {
    entityDataService.registerService(KE_SCHEDULES_WORKFLOWS_LIST_ENTITY_NAME, workflowsListService);
    super(KE_SCHEDULES_WORKFLOWS_LIST_ENTITY_NAME, serviceElementsFactory);
  }

  [name: string]: unknown;
}
