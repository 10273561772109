export const USER_FUNCTIONS_TABLE_COLUMNS_CONFIG = [
  {
    labelTranslate: 'user-functions-list.table.columns.name',
    fieldCode: 'name',
    fieldType: 'text',
    classStyle: 'no-breaks short-text',
    width: '16rem',
    resizable: true,
    sortable: true,
  },
  {
    labelTranslate: 'user-functions-list.table.columns.description',
    fieldCode: 'description',
    fieldType: 'text',
    classStyle: 'no-breaks short-text',
    width: '11rem',
    resizable: true,
    sortable: true,
  },
  {
    labelTranslate: 'user-functions-list.table.columns.tags',
    fieldCode: 'tags',
    fieldType: 'custom',
    classStyle: 'no-breaks short-text',
    width: '12rem',
    resizable: true,
    sortable: true,
  },
  {
    labelTranslate: 'user-functions-list.table.columns.scope',
    fieldCode: 'scopeType',
    fieldType: 'text',
    classStyle: 'no-breaks short-text',
    sortable: true,
    resizable: true,
    width: '12rem',
  },
  {
    labelTranslate: 'user-functions-list.table.columns.created',
    fieldCode: 'created',
    fieldType: 'date',
    classStyle: 'no-breaks',
    sortable: true,
    resizable: true,
    width: '9rem',
  },
  {
    labelTranslate: 'user-functions-list.table.columns.updated',
    fieldCode: 'updated',
    fieldType: 'date',
    classStyle: 'no-breaks',
    sortable: true,
    resizable: true,
    width: '9rem',
  },
  {
    labelTranslate: 'user-functions-list.table.columns.owner-name',
    fieldCode: 'ownerName',
    fieldType: 'text',
    classStyle: 'no-breaks',
    sortable: true,
    resizable: true,
    width: '9rem',
  },
  {
    labelTranslate: 'user-functions-list.table.columns.updated-by',
    fieldCode: 'editorName',
    fieldType: 'text',
    classStyle: 'no-breaks',
    sortable: true,
    resizable: true,
    width: '9rem',
  },
];
