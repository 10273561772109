import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormArray, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { AutoCompleteCompleteEvent, AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { RadioButtonModule } from 'primeng/radiobutton';
import { debounceTime, distinctUntilChanged, startWith } from 'rxjs';
import { DataSourcesAdditions } from '../../model/data-sources-additions.model';
import { validParse } from '../../utilities/data-sources-json.utility';
import { DataSourceFormService } from '../data-sources';
import {
  AdditionsFormGroup,
  AdditionsKeyValueFormGroup,
  TypeOfAdditions,
  TypeOfValue,
} from './data-source-additions.model';
import { validJsonValidator } from './valid-json.validator';

@Component({
  selector: 'selfai-platform-data-sources-additionals',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RadioButtonModule,
    AutoCompleteModule,
    DropdownModule,
    ButtonModule,
    InputTextModule,
    InputTextareaModule,
    InputSwitchModule,
  ],
  templateUrl: './data-sources-additions.component.html',
  styleUrls: ['./data-sources-additions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataSourcesAdditionsComponent implements OnInit {
  form: FormGroup<AdditionsFormGroup> = new FormGroup({
    type: new FormControl<TypeOfAdditions>('none', [Validators.required]),
    propertiesString: new FormControl<string | null>(null, [validJsonValidator]),
    propertiesArray: new FormArray<FormGroup<AdditionsKeyValueFormGroup>>([]),
  });

  filteredAdditionalList: string[] = [];
  keyTypeOptions = TypeOfValue;

  tempValue: string;

  get additionalKeys(): string[] {
    const usedPropertyKeys: string[] = this.form.controls.propertiesArray.length
      ? this.form.controls.propertiesArray.value.map(({ key }) => key)
      : [];

    return this.additionalList
      .map((item) => {
        return item.key;
      })
      .filter((key) => !usedPropertyKeys.includes(key));
  }

  get additions(): FormArray<FormGroup<AdditionsKeyValueFormGroup>> {
    return this.form.controls.propertiesArray;
  }

  @Input() properties: string | null | undefined;
  @Input() additionalList: DataSourcesAdditions[];
  @Output() changeEvent = new EventEmitter();

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private readonly dataSourceFormService: DataSourceFormService,
    private readonly destroyRef: DestroyRef,
  ) {}

  ngOnInit(): void {
    this.buildAdditionalForm();
    this.syncFormDisable();

    this.form.controls.type.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
      next: (type) => {
        this.buildAdditionalForm(type);
      },
    });

    this.form.controls.propertiesString.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef), debounceTime(500))
      .subscribe({
        next: (propertiesString) => {
          if (this.form.valid) {
            this.saveTempValue(propertiesString);
          }
        },
      });

    this.form.controls.propertiesArray.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef), debounceTime(500))
      .subscribe({
        next: (propertiesArray) => {
          if (this.form.valid) {
            this.saveTempValue(
              propertiesArray as {
                key: string;
                type: TypeOfValue;
                value: string | number | boolean;
              }[],
            );
          }
        },
      });
  }

  additionalSearch(value: AutoCompleteCompleteEvent): void {
    this.filteredAdditionalList = [];
    this.filteredAdditionalList = this.additionalKeys.filter((item: string) => {
      return item.toLowerCase().indexOf(value.query.toLowerCase()) > -1;
    });
  }

  selectedParam(key: string, index: number): void {
    const currentType = this.additionalList.find((item) => {
      return item.key === key;
    })?.type;
    this.additions.controls[index].patchValue({
      type: currentType,
      value: currentType === 'boolean' ? false : '',
    });
  }

  addParamsItem(): void {
    this.additions.push(this.additionalFormItem());
  }

  deleteParamsItem(index: number): void {
    this.additions.removeAt(index);
    this.saveTempValue(
      this.form.value.propertiesArray as {
        key: string;
        type: TypeOfValue;
        value: string | boolean;
      }[],
    );
  }

  private additionalFormItem(): FormGroup {
    const formGroup: FormGroup<AdditionsKeyValueFormGroup> = new FormGroup({
      key: new FormControl(null, [Validators.required]),
      type: new FormControl(null, [Validators.required]),
      value: new FormControl(null),
    });
    return formGroup;
  }

  private buildAdditionalForm(type?: TypeOfAdditions): void {
    let autoDetectedType = type;
    if (!type) {
      autoDetectedType = this.autoDetectType();
      this.form.controls.type.patchValue(autoDetectedType);
    }

    switch (autoDetectedType) {
      case 'text':
        this.form.controls.propertiesString.patchValue(this.properties);
        break;
      case 'key-value':
        {
          const properties: Record<string, string | number | boolean> = JSON.parse(
            this.tempValue || this.properties || '{}',
          );
          this.form.controls.propertiesArray.setValue([]);

          Object.entries(properties).forEach(([key, value]) => {
            const propertyForm = this.additionalFormItem();
            propertyForm.setValue({ key, type: this.detectValueType(value), value });
            this.additions.push(propertyForm);
          });
        }
        break;
      case 'none':
      default:
        this.form.controls.propertiesString.setValue(null);
        this.saveTempValue(undefined);
        break;
    }
    this.changeDetectorRef.detectChanges();
  }

  private saveTempValue(value: string | { key: string; value: string | number | boolean; type: TypeOfValue }[]): void {
    switch (this.form.value.type) {
      case 'text':
        this.tempValue = value as string;
        break;
      case 'key-value':
        {
          const obj: { [key: string]: string | number | boolean } = Object.fromEntries(
            (value as { key: string; value: string | number | boolean; type: TypeOfValue }[]).map((item) => [
              item.key,
              this.normalizeValueByType(item.value, item.type),
            ]),
          );
          this.tempValue = JSON.stringify(obj);
        }
        break;
      case 'none':
      default:
        this.tempValue = undefined;
        break;
    }
    this.changeEvent.emit(this.tempValue);
  }

  detectValueType(value: boolean | string | number): TypeOfValue {
    return typeof value === 'number' ? 'numeric' : (typeof value as 'string' | 'boolean');
  }

  private syncFormDisable() {
    this.dataSourceFormService.form.statusChanges
      .pipe(
        startWith(this.dataSourceFormService.form.status),
        distinctUntilChanged((prev, current) => {
          return current === 'DISABLED' || prev === 'DISABLED';
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((status) => {
        if (status === 'DISABLED') {
          this.form.disable({ emitEvent: false });
        } else {
          this.form.enable({ emitEvent: false });
        }
      });
  }

  private autoDetectType(): TypeOfAdditions {
    if (this.properties) {
      if (validParse(this.properties)) {
        return 'key-value';
      }
      return 'text';
    }
    return 'none';
  }

  private normalizeValueByType(value: string | number | boolean, type: TypeOfValue): string | number | boolean {
    switch (type) {
      case 'numeric':
        return Number(value);
      case 'boolean':
        return Boolean(value);
      case 'string':
      default:
        return value;
    }
  }
}
