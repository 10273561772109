import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, PristineChangeEvent, Validators } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CreateDataFrameColumnMappingFormGroup } from '@selfai-platform/pipeline-common';
import { WorkflowStateService } from '@selfai-platform/storage';
import { ConfirmationService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { MessageModule } from 'primeng/message';
import { TabViewModule } from 'primeng/tabview';
import { TooltipModule } from 'primeng/tooltip';
import { combineLatest, filter, Observable, skip } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { WorkflowReportDomainService } from '../../../../../wokflow-list';
import { DialogHeaderComponentModule } from '../../../dialog-header';
import { CreateDataComponent } from '../create-data';
import { CreateDataFrameComponent } from '../create-data-frame';
import { CreateDataFrameComponentService } from '../create-data-frame-component.service';

@Component({
  selector: 'selfai-platform-create-data-frame-container',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MessageModule,
    DialogHeaderComponentModule,
    TabViewModule,
    CreateDataFrameComponent,
    CreateDataComponent,
    ButtonModule,
    TooltipModule,
  ],
  providers: [CreateDataFrameComponentService],
  templateUrl: './create-data-frame-container.component.html',
  styleUrl: './create-data-frame-container.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateDataFrameContainerComponent implements OnInit {
  nodeId = this.createDataFrameComponentService.nodeId;
  hasParameters = this.createDataFrameComponentService.hasParameters;
  dataSourceInIniting: string | null = this.createDataFrameComponentService.nodeParameters.dataSourceId;
  form = this.createDataFrameComponentService.form;

  isAppying$: Observable<boolean> = this.createDataFrameComponentService.isApplying();

  get dataItems(): Record<string, string | number | boolean>[] {
    return this.createDataFrameComponentService.form.controls.dataOfFrame.value || [];
  }

  get dataSourceWasChanged(): boolean {
    return this.dataSourceInIniting !== this.form.controls.dataSourceId.value;
  }

  private nodeParameters = this.createDataFrameComponentService.nodeParameters;

  constructor(
    private readonly createDataFrameComponentService: CreateDataFrameComponentService,
    private readonly workflowReportDomainService: WorkflowReportDomainService,
    private readonly workflowStateService: WorkflowStateService,
    private readonly confirmationService: ConfirmationService,
    private readonly translateService: TranslateService,
    private readonly destroyRef: DestroyRef,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.prepareColumnMappingFormArray();
    this.form.setValue(this.nodeParameters);

    this.handleColumnAndDataChange();
    this.handleWorkflowStateChange();
  }

  onCreateDataChange(dataItems: Record<string, string | number | boolean>[]) {
    this.createDataFrameComponentService.form.controls.dataOfFrame.setValue(dataItems);
  }

  onClickApplyAndRun(): void {
    if (this.dataSourceWasChanged) {
      this.confirmationService.confirm({
        message: this.translateService.instant('workflow.cubes.create-data-frame.apply-and-run.warning'),
        accept: () => {
          this.createDataFrameComponentService.applyAndRun();
          this.cdr.markForCheck();
        },
      });
    } else {
      this.createDataFrameComponentService.applyAndRun();
      this.cdr.markForCheck();
    }
  }

  onCloseDialog(): void {
    this.createDataFrameComponentService.onCloseDialog();
  }

  onSubmit(): void {
    this.createDataFrameComponentService.onSubmit();
  }

  private handleWorkflowStateChange() {
    this.workflowStateService
      .getWorkflowState()
      .pipe(skip(1), takeUntilDestroyed(this.destroyRef), filter(Boolean))
      .subscribe(() => {
        this.createDataFrameComponentService.loadColumnsAndDataFromExcecutionReport();
      });
  }

  private handleColumnAndDataChange() {
    combineLatest({
      mappingFormEvent: this.form.controls.columnMapping.events,
      dataOfFrameFromEvent: this.form.controls.dataOfFrame.events,
    })
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter(
          ({ mappingFormEvent, dataOfFrameFromEvent }) =>
            mappingFormEvent instanceof PristineChangeEvent || dataOfFrameFromEvent instanceof PristineChangeEvent,
        ),
      )
      .subscribe(() => {
        // reset data source id if column mapping or data of frame has been changed
        this.form.controls.dataSourceId.setValue(null);
      });
  }

  private prepareColumnMappingFormArray() {
    if (this.nodeParameters.columnMapping.length > 0) {
      for (let i = 0; i < this.nodeParameters.columnMapping.length; i++) {
        this.form.controls.columnMapping.push(
          new FormGroup<CreateDataFrameColumnMappingFormGroup>({
            id: new FormControl(uuidv4()),
            columnName: new FormControl(null, [Validators.required]),
            columnType: new FormControl(null, [Validators.required]),
          }),
        );
      }
    } else if (this.nodeParameters.dataSourceId) {
      this.createDataFrameComponentService.loadColumnsAndDataFromExcecutionReport();
    }
  }
}
