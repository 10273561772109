import { inject, Pipe, PipeTransform } from '@angular/core';
import { NodeUsingColumn } from '@selfai-platform/pipeline-common';
import { Observable } from 'rxjs';
import { ColumnUsedInWorkflowService } from '../../services';

@Pipe({
  name: 'selfaiPlatformUsedIn',
  standalone: true,
})
export class UsedInPipe implements PipeTransform {
  private readonly columnUsedInWorkflowService = inject(ColumnUsedInWorkflowService);

  transform(columnName: string, excludeNodeId?: string): Observable<NodeUsingColumn[]> {
    return this.columnUsedInWorkflowService.getNodesUsingColumn(columnName, excludeNodeId);
  }
}
