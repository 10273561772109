<selfai-platform-data-list-view
  [layoutTypes]="['table']"
  [columns]="columns"
  [actions]="actions$ | async"
  [showEntityIcon]="true"
  [groupActions]="groupActions$ | async"
  [emptyMessage]="'shell.board.filter.ui.search-all.nodata' | translate"
  [globalFilterFields]="filtersFields"
>
</selfai-platform-data-list-view>
